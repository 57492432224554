import {createSlice} from '@reduxjs/toolkit'
import { fetchDeconnexion } from '../connexion/connexionSlice'
import {API_URL} from '../../Constants'
import axios from 'axios'

export const initialState = {
  loading: false,
  hasErrors: false,
  hasMore: true,
  loaded: false,
  donnee: true,
  finBandeControle: false,
  rapportfinancier: {},
}

const rapportfinancierSlice = createSlice({
  name: 'rapportfinancier',
  initialState,
  reducers: {
    getRapportFinancier: (state) => {
      state.loading = true;
      state.loaded = false;
      state.donnee = null; // Remet à null pour éviter les conflits
      state.hasErrors = false;
    },
    getRapportFinancierSuccess: (state, { payload }) => {
      state.rapportfinancier = payload;
      state.loading = false;
      state.hasErrors = false;
      state.loaded = true;
      state.donnee = true;
    },
    getBandeControleSuccess: (state, { payload }) => {
      state.rapportfinancier = payload;
      state.rapportfinancier.id_table = payload.id_table;
      state.loading = false;
      state.hasErrors = false;
      state.loaded = true;
      state.donnee = true;
    },
    getRapportFinancierFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
      state.loaded = true; // Chargement terminé malgré l'erreur
      state.donnee = false; // Pas de données en cas d'échec
    },
    getRapportFinancierAucuneDonnee: (state) => {
      state.loading = false;
      state.donnee = false;
      state.loaded = true; // Le chargement est terminé
      state.hasErrors = false; // Pas d'erreur, juste aucune donnée
    },
    getSuiteBandeControle: (state, { payload }) => {
      state.rapportfinancier.id_table = payload.id_table;
      state.rapportfinancier.sections = state.rapportfinancier.sections.concat(payload.sections);
      state.hasMore = true;
      state.finBandeControle = false;
    },
    setFinBandeControle: (state) => {
      state.hasMore = false;
      state.finBandeControle = true;
    },
  },
});


export const {getRapportFinancier, getRapportFinancierSuccess, getRapportFinancierFailure, getBandeControleSuccess, getRapportFinancierAucuneDonnee, getSuiteBandeControle, setFinBandeControle} = rapportfinancierSlice.actions

export const rapportfinancierSelector = (state) => state.rapportfinancier

export default rapportfinancierSlice.reducer

export function fetchRapportFinancier(service) {
  return async (dispatch, getState) => {
    dispatch(getRapportFinancier());

    const rapport = getState().rapport.rapport;
    const cumul = getState().rapports.cumul;
    const restaurant_choisi = getState().connexion.restaurant_choisi;

    try {
      const config = {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      };

      let response, data;

      if (cumul === true) {
        // Récupérer les dates pour le cumul
        const cumulData = getState().cumul.cumul;
        const date_min = cumulData.date_min;
        const date_max = cumulData.date_max;
        
        // Appel pour le cumul avec les dates
        response = await axios.get(
          `${API_URL}/web/rapport/cumul/${rapport.rapport_id}/${date_min}/${date_max}/0/${restaurant_choisi}`, 
          config
        );
      } else {
        // Appel pour le service unique avec id_hist et id_service
        response = await axios.get(
          `${API_URL}/web/rapport/${rapport.rapport_id}/${service.id_hist}/${service.num_tab_res}/0/${restaurant_choisi}/${service.id_service}`, 
          config
        );
      }

      data = await response.data;

      if (rapport.rapport_id === 5) {
        dispatch(getBandeControleSuccess(data));
      } else {
        dispatch(getRapportFinancierSuccess(data));
      }
      
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.status === 401) {
          dispatch(fetchDeconnexion());
        } else if (error.response.status === 404) {
          dispatch(getRapportFinancierAucuneDonnee());
        } else {
          dispatch(getRapportFinancierFailure());
        }
      } else {
        dispatch(getRapportFinancierFailure());
      }
    }
  };
}




export function fetchBandeControle(service) {
  return async (dispatch, getState) => {

    const rapport = getState().rapport.rapport;  

    const rapports_cumul = getState().rapports.cumul;  

    const cumul = getState().cumul.cumul;  

    const rapportfinancier = getState().rapportfinancier.rapportfinancier;

    const restaurant_choisi = getState().connexion.restaurant_choisi;  

    try {

      const config = { 
        headers: {'Content-Type' : 'application/json'},
        withCredentials: true
      }
      
      if(rapports_cumul == true) {

        const response = await axios.get(API_URL+'/web/rapport/cumul/'+rapport.rapport_id+'/'+cumul.date_min+'/'+cumul.date_max+'/'+rapportfinancier.id_table+'/'+restaurant_choisi, config)

        const data = await response.data

        dispatch(getSuiteBandeControle(data))

      } else {

        const response = await axios.get(API_URL+'/web/rapport/'+rapport.rapport_id+'/'+service.id_hist+'/'+service.num_tab_res+'/'+rapportfinancier.id_table+'/'+restaurant_choisi+'/'+service.id_service, config)

        const data = await response.data

        dispatch(getSuiteBandeControle(data))
      
      }

      if(rapportfinancier.sections.length >= rapportfinancier.taille)
        dispatch(setFinBandeControle())

    } catch (error) {

      if(error['response'] !== undefined) {
        if(error.response.status == 401) {
          dispatch(fetchDeconnexion())
        } else if (error.response.status == 404) {
          dispatch(getRapportFinancierAucuneDonnee())
        } else {
          dispatch(getRapportFinancierFailure())
        }
      } else {
        dispatch(getRapportFinancierFailure())
      }

    }

  }
}