import React, { useEffect, useRef, useState } from 'react';
import { fetchRapportFinancier, rapportfinancierSelector, fetchBandeControle, setFinBandeControle } from './rapportfinancierSlice';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button';
import { Rapport } from '../rapport/Rapport';
import { useReactToPrint } from 'react-to-print';
import { useSelector, useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container'
import format from "date-fns/format";
import fr from 'date-fns/locale/fr';
import Spinner from 'react-bootstrap/Spinner';
import styles from './RapportFinancier.module.css';

import {
  Redirect,
  useHistory,
  useParams,
  withRouter
} from "react-router-dom";

export const RapportFinancier = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const history = useHistory();
  const dispatch = useDispatch()
  const service = useSelector(state => state.service.service)
  const cumul = useSelector(state => state.cumul.cumul)
  const rapports = useSelector(state => state.rapports)
  const rapport = useSelector(state => state.rapport.rapport)
  const [titreDonnees, setTitreDonnees] = useState([]);
  const { rapportfinancier, loading, hasErrors, loaded, donnee, hasMore, finBandeControle } = useSelector(rapportfinancierSelector)
  const [hasLess, sethasLess] = useState(true);
  const [pause, setPause] = useState(false);
  let timer;

  const [isSmallScreen600, setIsSmallScreen600] = useState(window.innerWidth < 600);
  const [isSmallScreen850, setIsSmallScreen850] = useState(window.innerWidth <= 850 && window.innerWidth >= 600);
  const [isSmallScreen655, setIsSmallScreen655] = useState(window.innerWidth <= 655);
  const [isSmallScreen599, setIsSmallScreen599] = useState(window.innerWidth <= 599);
  const [isSmallScreen1024, setIsSmallScreen1024] = useState(window.innerWidth <= 1024 && window.innerWidth > 655);

  useEffect(() => {
    if (rapport && service && service.length > 0 && service[0].id_service) {
      dispatch(fetchRapportFinancier(service[0]));
    }
  }, [dispatch, service, rapport]);  

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen1024(window.innerWidth <= 1024 && window.innerWidth > 655);
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isSmallScreen1024]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen850(window.innerWidth <= 850 && window.innerWidth >= 600);
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isSmallScreen850]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen600(window.innerWidth < 600);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isSmallScreen600]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen655(window.innerWidth <= 655);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isSmallScreen655]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen599(window.innerWidth <= 599);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isSmallScreen599]);

  useEffect(() => {
    if (rapportfinancier.sections.length > 0 && rapportfinancier.sections[0].tableaux.length > 0) {
      const donnees = rapportfinancier.sections[0].tableaux[0].lignes[0].ligne_donnee;
      setTitreDonnees(donnees);
    }
  }, [rapportfinancier]);

  const [isMenuOpen, setIsMenuOpen] = useState(false); // État pour contrôler l'ouverture du menu

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Bascule entre ouvert et fermé
  };

  const renderRapportList = () => {
    if(isSmallScreen599){
      //version mobile
      return (
      <div className={styles.mobileWrapper}>
        
        {/* Menu coulissant */}
        <div className={`${styles.slideMenu} ${isMenuOpen ? styles.menuOpen : ''}`}>
          {rapports.rapports.map((rapport) => (
            <Rapport key={rapport.rapport_id} rapport={rapport} side={true} />
          ))}
          {/* Bouton pour ouvrir/fermer le menu */}
          <button className={`${styles.menuButton} ${isMenuOpen ? styles.menuButtonOpen : ''}`} onClick={toggleMenu}>
            {/* Afficher chevron selon l'état du menu */}
            {isMenuOpen ? (
              <img className={`${styles.chevron}`} src={`${process.env.PUBLIC_URL}/chevron-left.svg`} alt="Fermer le menu" />
            ) : (
              <img className={`${styles.chevron}`} src={`${process.env.PUBLIC_URL}/chevron-right.svg`} alt="Ouvrir le menu" />
            )}
          </button>
          {/* Démarcation et lien d'impression */}
          <div className={styles.menuDividerContainer}>
            <hr className={styles.menuDivider} />
          </div>
          <a href="#" onClick={handlePrint} className={styles.printLink}>
            Imprimer la page
          </a>
        </div>
      </div>
    );
    }else{
      //version tablette et pc
      let side = !isSmallScreen599;
      return rapports.rapports.map((rapport) => (
        <Rapport key={rapport.rapport_id} rapport={rapport} side={side} />
      ));
    }
  };
  
  useEffect(() => {
    if (rapport.rapport_id == 5) {
      if (rapportfinancier.sections.length >= rapportfinancier.taille) {
        dispatch(setFinBandeControle())
        clearInterval(timer);
        return;
      } else {
        if (hasLess) {
          timer = setInterval(() => {
            dispatch(fetchBandeControle(service[0]))
          }, 5000);  
        }
      }
      return () => {
        clearInterval(timer);
      };
    }
  }, [hasLess, rapportfinancier, dispatch, service])

  const fonctionPause = () => {
    clearInterval(timer);
    sethasLess(false)
    setPause(true)
  }

  const fonctionReprise = () => {
    sethasLess(true)
    setPause(false)
  }

// Header pour les Totaux
const renderHeaderTotaux = (ligne, isSmallScreen655, tableau_id) => {
  if (ligne.ligne_type === 'titre') {
    if (rapport.rapport_id == 1) {
        // Modification spécifique pour rapport_id 1
        if (isSmallScreen655) {
          if(tableau_id == 1){
            return (
              <tr className={styles.rapport4_table}>
                {ligne.ligne_donnee.reduce((acc, donnee, index) => {
                  if (index % 2 === 0) {
                    acc.push(
                      <th className={styles.rapport4_table} key={index} style={{ fontWeight: 'bold', textAlign: 'center', padding: '8px' }}>
                        {donnee}
                      </th>
                    );
                    if (ligne.ligne_donnee[index + 1]) {
                      acc.push(
                        <th className={styles.rapport4_table} key={index + 1} style={{ fontWeight: 'bold', textAlign: 'center', padding: '8px' }}>
                          {ligne.ligne_donnee[index + 1]}
                        </th>
                      );
                    }
                  }
                  return acc;
                }, [])}
              </tr>
            );
          }
          if(tableau_id == 2){
            return (
              <>
                <tr>
                  <th colSpan={ligne.ligne_donnee.length}>{ligne.ligne_donnee[0]}</th>
                </tr>
                <tr>
                  {ligne.ligne_donnee.slice(1).map((donnee, index) => (
                    <th key={index}>{donnee}</th>
                  ))}
                </tr>
              </>
            );
          }
        } else {
          // Modification pour aligner les en-têtes avec les deux colonnes côte à côte
          return (
            <tr className={styles.rapport4_table}>
              {ligne.ligne_donnee.reduce((acc, donnee, index) => {
                if (index % 2 === 0) {
                  acc.push(
                    <th className={styles.rapport4_table} key={index} style={{ fontWeight: 'bold', textAlign: 'center', padding: '8px' }}>
                      {donnee}
                    </th>
                  );
                  if (ligne.ligne_donnee[index + 1]) {
                    acc.push(
                      <th className={styles.rapport4_table} key={index + 1} style={{ fontWeight: 'bold', textAlign: 'center', padding: '8px' }}>
                        {ligne.ligne_donnee[index + 1]}
                      </th>
                    );
                  }
                }
                return acc;
              }, [])}
            </tr>
          );
        }
      }else if (rapport.rapport_id == 4){
        if (isSmallScreen655) {
          return (
            <>
              <tr className={styles.rapport4_table}>
                <th className={styles.rapport4_table} colSpan={5}>{ligne.ligne_donnee[0]}</th> {/* Groupe */}
              </tr>
              <tr className={styles.rapport4_table}>
                <th className={styles.rapport4_table}>
                  <div>{ligne.ligne_donnee[1]}</div> {/* T.T.C. */}
                  <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[2]}</div> {/* H.T./H.S. */}
                </th>
                <th className={styles.rapport4_table}>
                  <div>{ligne.ligne_donnee[3]}</div> {/* T.V.A. */}
                  <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[4]}</div> {/* Service */}
                </th>
              </tr>
            </>
          );
        }else {
          return (
            <tr className={styles.rapport4_table}>
              {ligne.ligne_donnee.map((donnee, index) => (
                <th className={styles.rapport4_table} key={index}>
                  {donnee}
                </th>
              ))}
            </tr>
          );
        }
      } else {
        if (ligne.ligne_type === 'titre') {
          return (ligne.ligne_donnee.map((donnee) => <th>{donnee}</th>));
        }
      }
    }
  };

  // Body pour les Totaux
  const renderBodyTotaux = (ligne, isSmallScreen655, tableau_id) => {
    if (ligne.ligne_type === 'valeur') {
      if (rapport.rapport_id == 1) {
        // Modification spécifique pour rapport_id 1
        if (isSmallScreen655) {
          if(tableau_id == 1){
            return (
              <>
                {ligne.ligne_donnee.reduce((acc, donnee, index) => {
                  // On s'assure que toutes les lignes soient bien prises en compte
                  if (index % 2 === 0) {
                    acc.push(
                      <tr key={index} style={{ borderBottom: '1px solid #e7e7e7' }}>
                        <td className={styles.rapport4_table} style={{ textAlign: 'center', padding: '8px' }}>
                          {donnee || '0'}
                        </td>
                        {ligne.ligne_donnee[index + 1] !== undefined && (
                          <td className={styles.rapport4_table} style={{ textAlign: 'center', padding: '8px' }}>
                            {ligne.ligne_donnee[index + 1] || '0'}
                          </td>
                        )}
                      </tr>
                    );
                  }
                  return acc;
                }, [])}
              </>
            );
          }
          if(tableau_id == 2){
            return (
              <>
                {/* Chaque ligne occupe une seule colonne en petit écran */}
                <tr style={{borderBottom: '1px solid #e7e7e7'}}>
                  <th colSpan={ligne.ligne_donnee.length} style={{ fontWeight: 'normal', textAlign: 'center', backgroundColor: '#e7e7e7', padding: '8px 0px' }}>{ligne.ligne_donnee[0]}</th>
                </tr>
                <tr style={{borderBottom: '1px solid #e7e7e7'}}>
                  {ligne.ligne_donnee.slice(1).map((donnee, index) => (
                    <th key={index} style={{ fontWeight: 'normal', textAlign: 'center', border: '1px solid #DDDDDD', padding: '8px 0px' }}>{donnee}</th>
                  ))}
                </tr>
              </>
            );
          }
        } else {
          // Modification pour afficher deux colonnes par ligne pour les grands écrans
          if(tableau_id == 1){
            return (
              <>
                {ligne.ligne_donnee.reduce((acc, donnee, index) => {
                  // On s'assure que toutes les lignes soient bien prises en compte
                  if (index % 2 === 0) {
                    acc.push(
                      <tr key={index} style={{ borderBottom: '1px solid #e7e7e7' }}>
                        <td className={styles.rapport4_table} style={{ textAlign: 'center', padding: '8px' }}>
                          {donnee || '0'}
                        </td>
                        {ligne.ligne_donnee[index + 1] !== undefined && (
                          <td className={styles.rapport4_table} style={{ textAlign: 'center', padding: '8px' }}>
                            {ligne.ligne_donnee[index + 1] || '0'}
                          </td>
                        )}
                      </tr>
                    );
                  }
                  return acc;
                }, [])}
              </>
            );
          }
          if(tableau_id == 2){
            return (
              <>
                {/* Toutes les valeurs sont affichées sur une seule ligne */}
                <tr style={{ border: '1px solid #e7e7e7' }}>
                  {ligne.ligne_donnee.map((donnee, index) => (
                    <th key={index} style={{ fontWeight: 'normal', textAlign: 'center', border: '1px solid #DDD', padding: '8px 0px' }}>
                      {donnee}
                    </th>
                  ))}
                </tr>
              </>
            );          
          }
        }
      } else if (rapport.rapport_id == 4){
        const firstCellClass = `${styles.rapport4_table} ${styles.first_cell}`; // Classe spéciale pour la première cellule
        if (isSmallScreen655) {
          return (
            <>
              <tr className={styles.rapport4_table}>
                <td className={firstCellClass} colSpan={5}>{ligne.ligne_donnee[0]}</td> {/* Groupe */}
              </tr>
              <tr className={styles.rapport4_table}>
                <td className={styles.rapport4_table}>
                  <div>{ligne.ligne_donnee[1]}</div> {/* T.T.C. */}
                  <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[2]}</div> {/* H.T./H.S. */}
                </td>
                <td className={styles.rapport4_table}>
                  <div>{ligne.ligne_donnee[3]}</div> {/* T.V.A. */}
                  <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[4]}</div> {/* Service */}
                </td>
              </tr>
            </>
          );
        } else {
          return (
            <tr className={styles.rapport4_table}>
              <td className={firstCellClass}>{ligne.ligne_donnee[0]}</td> {/* Groupe */}
              {ligne.ligne_donnee.slice(1).map((donnee, index) => (
                <td className={styles.rapport4_table} key={index}>
                  {donnee} {/* T.T.C., H.T./H.S., T.V.A., Service */}
                </td>
              ))}
            </tr>
          );
        }
      } else {
        if (ligne.ligne_type === 'valeur') {
          return (ligne.ligne_donnee.map((donnee, index) => <td id={index}>{donnee}</td>));
        }
      }
    }
  };

  // Header pour Famille
  const renderHeaderFamille = (ligne, isSmallScreen655, isSmallScreen1024, isSmallScreen600, isSmallScreen850, index) => {
    const screenSize = isSmallScreen655 
    ? "isSmallScreen655" 
    : isSmallScreen1024 
    ? "isSmallScreen1024" 
    : null;

    if (ligne.ligne_type === 'titre') {
      if (rapport.rapport_id == 1) {
        if (index % 2 === 0) {
          // Tableau pair avec 3 colonnes
          if (isSmallScreen655) {
            return (
              <tr className={styles.rapport4_table}>
                {ligne.ligne_donnee.reduce((acc, donnee, index) => {
                  if (index % 2 === 0) {
                    acc.push(
                      <th className={styles.rapport4_table} key={index} style={{ fontWeight: 'bold', textAlign: 'center', padding: '8px' }}>
                        {donnee}
                      </th>
                    );
                    if (ligne.ligne_donnee[index + 1]) {
                      acc.push(
                        <th className={styles.rapport4_table} key={index + 1} style={{ fontWeight: 'bold', textAlign: 'center', padding: '8px' }}>
                          {ligne.ligne_donnee[index + 1]}
                        </th>
                      );
                    }
                  }
                  return acc;
                }, [])}
              </tr>
            );
          } else {
            return (
              <tr>
                {ligne.ligne_donnee.slice(0, 3).map((donnee, index) => (
                  <td key={index} style={{ textAlign: 'center', borderBottom: '1px solid #ddd', backgroundColor: '#262626', color: 'white'}}>{donnee}</td>
                ))}
              </tr>
            );
          }
        } else {
          // Tableau impair avec 5 colonnes
          if (isSmallScreen655) {
            return (
              <>
                <tr>
                  <td colSpan={5} style={{ textAlign: 'center', backgroundColor: '#262626', color: 'white'}}>
                    {ligne.ligne_donnee[0]}
                  </td>
                </tr>
                <tr>
                  {ligne.ligne_donnee.slice(1, 6).map((donnee, index) => (
                    <td key={index} style={{ textAlign: 'center', borderBottom: '1px solid #ddd', backgroundColor: '#262626', color: 'white'}}>{donnee}</td>
                  ))}
                </tr>
              </>
            );
          } else {
            return (
              <tr>
                {ligne.ligne_donnee.slice(0, 5).map((donnee, index) => (
                  <td key={index} style={{ textAlign: 'center', borderBottom: '1px solid #ddd', backgroundColor: '#262626', color: 'white'}}>{donnee}</td>
                ))}
              </tr>
            );
          }
        }
      } else if (rapportfinancier.rapport_id == 2) {
        if (ligne.ligne_type == 'titre') {
          if (isSmallScreen600) {
            // Logique pour les écrans < 600px
            return (
              <tr className={styles.rapport4_table} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                {ligne.ligne_donnee.map((donnee, index) => {
                  if (index === 0) {
                    return (
                      <th key={index} className={styles.tableHeureCelltitre} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                        <div>Table</div>
                        <div style={{ borderTop: '1px solid #ccc', marginTop: '4px', paddingTop: '4px' }}>Heure</div>
                      </th>
                    );
                  }
                  if (index === 1) {
                    return null; // Masquer "Heure" sous 600px
                  }
                  if (index === 2) {
                    return (
                      <th key={index} className={`${styles.rapport4_table} ${styles.mobileHide}`} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                        {donnee}
                      </th>
                    );
                  }
                  if (index === 6) {
                    return null;
                  }
                  return (
                    <th key={index} className={styles.rapport4_table} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                      {donnee}
                    </th>
                  );
                })}
              </tr>
            );
          } else if (isSmallScreen850) {
            // Logique pour les écrans entre 600px et 850px
            return (
              <tr className={styles.rapport4_table} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                {ligne.ligne_donnee.map((donnee, index) => {
                  if (index === 0) {
                    return (
                      <th key={index} className={styles.tableHeureCelltitre} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                        <div>Table</div>
                        <div style={{ borderTop: '1px solid #ccc', marginTop: '4px', paddingTop: '4px' }}>Heure</div>
                      </th>
                    );
                  }
                  if (index === 1) {
                    return null; // Masquer "Heure" sous 600px
                  }
                  if (index === 6) {
                    return null;
                  }
                  return (
                    <th key={index} className={styles.rapport4_table} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                      {donnee}
                    </th>
                  );
                })}
              </tr>
            );
          } else {
            // Logique pour les écrans au-delà de 850px
              // Header tableau pour les rapports d'annulation et d'offert
              return (
                <tr className={styles.rapport4_table} style={{backgroundColor: '#262626', textAlign: 'center'}}> {/* Classe spécifique pour l'en-tête */}
                  {ligne.ligne_donnee.map(function(donnee, index) {
                    if (index === 6) { // Ne pas afficher la colonne 'Type' dans l'en-tête
                      return null;
                    }
                    return <th key={index} className={styles.rapport4_table} style={{backgroundColor: '#262626', textAlign: 'center'}}>{donnee}</th>; {/* Classe spécifique pour chaque cellule */}
                  })}
                </tr>
              );
          }
        }
      }else if (rapportfinancier.rapport_id == 3) {
        if (ligne.ligne_type == 'titre') {        
          if (isSmallScreen600) {
            // Logique pour les écrans < 600px
            return (
              <tr className={styles.rapport4_table} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                {ligne.ligne_donnee.map((donnee, index) => {
                  if (index === 0) {
                    return (
                      <th key={index} className={styles.tableHeureCelltitre} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                        <div>Table</div>
                        <div style={{ borderTop: '1px solid #ccc', marginTop: '4px', paddingTop: '4px' }}>Heure</div>
                      </th>
                    );
                  }
                  if (index === 1) {
                    return null; // Masquer "Heure" sous 600px
                  }
                  if (index === 2) {
                    return (
                      <th key={index} className={`${styles.rapport4_table} ${styles.mobileHide}`} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                        {donnee}
                      </th>
                    );
                  }
                  if (index === 6) {
                    return null;
                  }
                  return (
                    <th key={index} className={styles.rapport4_table} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                      {donnee}
                    </th>
                  );
                })}
              </tr>
            );
          } else if (isSmallScreen850) {
            // Logique pour les écrans entre 600px et 850px
            return (
              <tr className={styles.rapport4_table} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                {ligne.ligne_donnee.map((donnee, index) => {
                  if (index === 0) {
                    return (
                      <th key={index} className={styles.tableHeureCelltitre} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                        <div>Table</div>
                        <div style={{ borderTop: '1px solid #ccc', marginTop: '4px', paddingTop: '4px' }}>Heure</div>
                      </th>
                    );
                  }
                  if (index === 1) {
                    return null; // Masquer "Heure" sous 600px
                  }
                  if (index === 6) {
                    return null;
                  }
                  return (
                    <th key={index} className={styles.rapport4_table} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                      {donnee}
                    </th>
                  );
                })}
              </tr>
            );
          } else {
            // Logique pour les écrans au-delà de 850px
              // Header tableau pour les rapports d'annulation et d'offert
              return (
                <tr className={styles.rapport4_table} style={{backgroundColor: '#262626', textAlign: 'center'}}> {/* Classe spécifique pour l'en-tête */}
                  {ligne.ligne_donnee.map(function(donnee, index) {
                    if (index === 6) { // Ne pas afficher la colonne 'Type' dans l'en-tête
                      return null;
                    }
                    return <th key={index} className={styles.rapport4_table} style={{backgroundColor: '#262626', textAlign: 'center'}}>{donnee}</th>; {/* Classe spécifique pour chaque cellule */}
                  })}
                </tr>
              );
          }
        }
      } else if (rapportfinancier.rapport_id == 7) {
        // Header tableau pour le rapport offert perso
        if (ligne.ligne_type == 'titre') {
          if (isSmallScreen600) {
            return (
              <tr className={styles.rapport4_table} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                {ligne.ligne_donnee.map((donnee, index) => {
                  if (index === 0) {
                    return (
                      <th key={index} className={styles.tableHeureCell} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                        <div>Table</div>
                        <div style={{ borderTop: '1px solid #ccc', marginTop: '4px', paddingTop: '4px' }}>Heure</div>
                      </th>
                    );
                  }
                  if(index === 1){
                    return null
                  }
                  if (index === 2) {
                    return (
                      <th key={index} className={`${styles.rapport4_table} ${styles.mobileHide}`} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                        {donnee}
                      </th>
                    );
                  }
                  if (index === 6) {
                    return null;
                  }
                  return (
                    <th key={index} className={styles.rapport4_table} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                      {donnee}
                    </th>
                  );
                })}
              </tr>
            );
          } else if (isSmallScreen850) {
            // Logique pour les écrans entre 600px et 850px
            return (
              <tr className={styles.rapport4_table} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                {ligne.ligne_donnee.map((donnee, index) => {
                  if (index === 0) {
                    return (
                      <th key={index} className={styles.tableHeureCell} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                        <div>Table</div>
                        <div style={{ borderTop: '1px solid #ccc', marginTop: '4px', paddingTop: '4px' }}>Heure</div>
                      </th>
                    );
                  }
                  if(index === 1){
                    return null
                  }
                  if (index === 6) {
                    return null;
                  }
                  return (
                    <th key={index} className={styles.rapport4_table} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                      {donnee}
                    </th>
                  );
                })}
              </tr>
            );
          } else {
            // Logique pour les écrans au-delà de 850px
            return (
              <tr className={styles.rapport4_table} style={{backgroundColor: '#262626', textAlign: 'center'}}> {/* Classe spécifique pour l'en-tête */}
                {ligne.ligne_donnee.map(function(donnee, index) {
                  if (index === 6) { // Ne pas afficher la colonne 'Type' dans l'en-tête
                    return null;
                  }
                  return <th key={index} className={styles.rapport4_table} style={{backgroundColor: '#262626', textAlign: 'center'}}>{donnee}</th>; {/* Classe spécifique pour chaque cellule */}
                })}
              </tr>
            );
          }
        }
      } else if (rapport.rapport_id == 4){
          const columnCount = ligne.ligne_donnee.length - 1; // Compte les colonnes à afficher (sans le Code)
          if (rapports.cumul) {
                      // Switch case pour les différentes tailles d'écran
            switch (screenSize) {
                case "isSmallScreen655":
                  // Cas où la fenêtre est <= 655px
                  const filteredDonnees655 = ligne.ligne_donnee.filter((donnee) => donnee.toLowerCase() !== 'ratios'); // Enlever "Ratios"
                  return (
                    <>
                      <tr className={styles.rapport4_table}>
                        <th className={styles.rapport4_table}>{filteredDonnees655[0]}</th> {/* Code */}
                        <th className={styles.rapport4_table} colSpan={2}>{filteredDonnees655[1]}</th> {/* Désignation avec colSpan sous 655px */}
                      </tr>
                      <tr className={styles.rapport4_table}>
                        <th className={styles.rapport4_table}>
                          <div>{ligne.ligne_donnee[2]}</div> {/* T.T.C. */}
                          <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[4]}</div> {/* H.T./H.S. */}
                        </th>
                        <th className={styles.rapport4_table}>
                          <div>{ligne.ligne_donnee[3]}</div> {/* T.V.A. */}
                          <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[5]}</div> {/* Service */}
                        </th>
                        <th className={styles.rapport4_table}>{ligne.ligne_donnee[6]}</th>
                      </tr>
                    </>
                  );
                case "isSmallScreen1024":
                  // Cas où la fenêtre est entre 655px et 1024px
                  const filteredDonnees1024 = ligne.ligne_donnee.filter((donnee) => donnee.toLowerCase() !== 'ratios'); // Enlever "Ratios"
                  return (
                    <>
                      <tr className={styles.rapport4_table}>
                        <th className={styles.rapport4_table}>{filteredDonnees1024[0]}</th> {/* Code */}
                        <th className={styles.rapport4_table}>{filteredDonnees1024[1]}</th> {/* Désignation sans colSpan pour les écrans > 655px */}
                        {filteredDonnees1024.slice(2).map((donnee, index) => (
                          <th className={styles.rapport4_table} key={index}>
                            {donnee} {/* QTE Vendus, T.T.C., etc. */}
                          </th>
                        ))}
                      </tr>
                    </>
                  );
                
                default:
                  // Cas par défaut (logique actuelle de l'else)
                  return (
                    <tr className={styles.rapport4_table}>
                      <th className={styles.rapport4_table}>{ligne.ligne_donnee[0]}</th> {/* Code */}
                      <th className={styles.rapport4_table}>{ligne.ligne_donnee[1]}</th> {/* Désignation sans colSpan pour les écrans > 655px */}
                      {ligne.ligne_donnee.slice(2).map((donnee, index) => (
                        <th className={styles.rapport4_table} key={index}>
                          {donnee} {/* QTE Vendus, T.T.C., etc. */}
                        </th>
                      ))}
                    </tr>
                  );
            }
          } else {
          const filteredDonnees = ligne.ligne_donnee.filter(donnee => {
            if (typeof donnee === 'string') {
              return donnee.trim().toLowerCase() !== 'ratios';
            }
            return true;
          });

          if (isSmallScreen655) {
            return (
              <>
                <tr className={styles.rapport4_table}>
                  <th className={styles.rapport4_table}>{filteredDonnees[0]}</th> {/* Code */}
                  <th className={styles.rapport4_table} colSpan={2}>{filteredDonnees[1]}</th> {/* Désignation avec colSpan sous 655px */}
                </tr>
                <tr className={styles.rapport4_table}>
                  <th className={styles.rapport4_table}>
                    <div>{ligne.ligne_donnee[2]}</div> {/* T.T.C. */}
                    <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[4]}</div> {/* H.T./H.S. */}
                  </th>
                  <th className={styles.rapport4_table}>
                    <div>{ligne.ligne_donnee[3]}</div> {/* T.V.A. */}
                    <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[5]}</div> {/* Service */}
                  </th>
                  <th className={styles.rapport4_table}>{ligne.ligne_donnee[6]}</th>
                </tr>
              </>
            );
          } else {
            return (
              <tr className={styles.rapport4_table}>
                <th className={styles.rapport4_table}>{filteredDonnees[0]}</th> {/* Code */}
                <th className={styles.rapport4_table}>{filteredDonnees[1]}</th> {/* Désignation sans colSpan pour les écrans > 655px */}
                {filteredDonnees.slice(2).map((donnee, index) => (
                  <th className={styles.rapport4_table} key={index}>
                    {donnee} {/* QTE Vendus, T.T.C., etc. */}
                  </th>
                ))}
              </tr>
            );
          }
        }
      } else if (rapport.rapport_id == 6){
        const columnCount = ligne.ligne_donnee.length - 1; // Compte les colonnes à afficher (sans le Code)
        if (rapports.cumul) {
          // Filtrez les données pour enlever "ratios" si nécessaire et ajustez la visualisation pour les écrans > 655px
          const filteredDonnees1024 = ligne.ligne_donnee.filter((donnee) => donnee.toLowerCase() !== 'ratios');
        
          return (
            <tr className={styles.rapport6_table}> {/* Utilisez la nouvelle classe pour centrer les éléments */}
              {filteredDonnees1024.map((donnee, index) => (
                <th className={styles.rapport6_table} key={index}>
                  {donnee} {/* Affichez le code, la désignation et les autres données comme QTE Vendus, T.T.C., etc. */}
                </th>
              ))}
            </tr>
          );
        } else {
          const filteredDonnees = ligne.ligne_donnee.filter(donnee => {
            if (typeof donnee === 'string') {
              return donnee.trim().toLowerCase() !== 'ratios';
            }
            return true;
          });
          return (
            <tr className={styles.rapport4_table}>
              <th className={styles.rapport4_table}>{filteredDonnees[0]}</th> {/* Code */}
              <th className={styles.rapport4_table}>{filteredDonnees[1]}</th> {/* Désignation sans colSpan pour les écrans > 655px */}
              {filteredDonnees.slice(2).map((donnee, index) => (
                <th className={styles.rapport4_table} key={index}>
                  {donnee} {/* QTE Vendus, T.T.C., etc. */}
                </th>
              ))}
            </tr>
          );
        }
    } else {
        if (ligne.ligne_type === 'valeur') {
          return (ligne.ligne_donnee.map((donnee, index) => <td id={index}>{donnee}</td>));
        }
      }
    }
  };

  // Body pour Famille
  const renderBodyFamille = (ligne, isSmallScreen655, isSmallScreen1024, isSmallScreen600, isSmallScreen850, index, tableau_id) => {
    if (rapport.rapport_id == 1) {
      if(ligne.ligne_type == 'valeur') {
        if (index % 2 === 0) {
          // Tableau pair avec 3 colonnes
          if (isSmallScreen655) {
            return (
              <>
                {ligne.ligne_donnee.reduce((acc, donnee, index) => {
                  // On s'assure que toutes les lignes soient bien prises en compte
                  if (index % 2 === 0) {
                    acc.push(
                      <tr key={index} style={{ borderBottom: '1px solid #e7e7e7' }}>
                        <td className={styles.rapport4_table} style={{ textAlign: 'center', padding: '8px' }}>
                          {donnee || '0'}
                        </td>
                        {ligne.ligne_donnee[index + 1] !== undefined && (
                          <td className={styles.rapport4_table} style={{ textAlign: 'center', padding: '8px' }}>
                            {ligne.ligne_donnee[index + 1] || '0'}
                          </td>
                        )}
                      </tr>
                    );
                  }
                  return acc;
                }, [])}
              </>
            );
          }else {
            return (
              <tr>
                {ligne.ligne_donnee.slice(0, 3).map((donnee, index) => (
                  <td key={index} style={{ textAlign: 'center', border: '1px solid #ddd'}}>{donnee}</td>
                ))}
              </tr>
            );
          }
        } else {
          // Tableau impair avec 5 colonnes
          if (isSmallScreen655) {
            return (
              <>
                <tr>
                  <td colSpan={5}>{ligne.ligne_donnee[0]}</td>
                </tr>
                <tr>
                  {ligne.ligne_donnee.slice(1, 6).map((donnee, index) => (
                    <td key={index} style={{ textAlign: 'center', border: '1px solid #ddd', backgroundColor: '#e7e7e7'}}>{donnee}</td>
                  ))}
                </tr>
              </>
            );
          } else {
            return (
              <tr>
                {ligne.ligne_donnee.slice(0, 5).map((donnee, index) => (
                  <td key={index} style={{ textAlign: 'center', border: '1px solid #ddd'}}>{donnee}</td>
                ))}
              </tr>
            );
          }
        }
      }
    }else if (rapportfinancier.rapport_id == 2) {
      if (ligne.ligne_type == 'valeur') {
        if (isSmallScreen600) {
          // Gérer les lignes de totaux spécifiquement
          if (ligne.ligne_donnee[0] === 'Total annulations' || ligne.ligne_donnee[0] === 'Total offerts') {
            return (
              <>
                <td key="total-label" colSpan="2" className={styles.total} style={{ textAlign: 'center' }}>
                  {ligne.ligne_donnee[0]}
                </td>
                <td key="total-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
                <td key="total-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
                <td></td>
              </>
            );
          }
          if (ligne.ligne_donnee[0] === 'Total tous serveurs') {
            return (
              <>
                <td key="total-servers-label" colSpan="2" className={styles.total} style={{ textAlign: 'center' }}>
                  {ligne.ligne_donnee[0]}
                </td>
                <td key="total-servers-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
                <td key="total-servers-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
                <td></td>
                <td></td>
              </>
            );
          }
    
          // Affichage normal pour les autres lignes de données
          return ligne.ligne_donnee.map((donnee, index) => {    
            // Fusionner Table et Heure sous 600px
            if (index === 0) {
              return (
                <td key={index} className={`${styles.tableHeureCell}`} style={{ textAlign: 'center' }}>
                  <div>{ligne.ligne_donnee[0]}</div>
                  <div style={{ borderTop: '1px solid #ccc', paddingTop: '4px' }}>
                    {ligne.ligne_donnee[1].substring(0, 5)}
                  </div>
                </td>
              );
            }
          
            // Ne pas afficher la colonne Heure après fusion avec Table
            if (index === 1) {
              return null;
            }
    
            // Masquer la colonne Code sous 600px
            if (index === 2) {
              return (
                <td key={index} className={styles.mobileHide} style={{ textAlign: 'center' }}>
                  {donnee}
                </td>
              );
            }
    
            // Fusionner Prix et Type
            if (index === 5) {
              const prixTypeValue = `${ligne.ligne_donnee[5]} ${ligne.ligne_donnee[6]}`;
              return <td key={index} style={{ textAlign: 'center' }}>{prixTypeValue}</td>;
            }
          
            // Ne pas afficher la colonne Type après fusion avec Prix
            if (index === 6) {
              return null;
            }
          
            // Affichage conditionnel de la colonne raison pour le type annulation (rapport_id = 2)
            if (rapportfinancier.rapport_id === 2 && index === ligne.ligne_donnee.length - 1) {
              return <td key={index} style={{ textAlign: 'center' }}>{donnee}</td>;
            } else if (rapportfinancier.rapport_id !== 2 && index === ligne.ligne_donnee.length - 1) {
              return null;
            } else {
              return <td key={index} style={{ textAlign: 'center', borderTop: "1px solid #e0e0e0" }}>{donnee}</td>;
            }
          }
        );
      } else if (isSmallScreen850) {
        // Logique pour les écrans entre 600px et 850px
        if (ligne.ligne_donnee[0] === 'Total annulations' || ligne.ligne_donnee[0] === 'Total offerts') {
          return (
            <>
              <td key="total-label" colSpan="3" className={styles.total} style={{ textAlign: 'center' }}>
                {ligne.ligne_donnee[0]}
              </td>
              <td key="total-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
              <td key="total-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
              <td></td>
            </>
          );
        }
      
        if (ligne.ligne_donnee[0] === 'Total tous serveurs') {
          return (
            <>
              <td key="total-servers-label" colSpan="2" className={styles.total} style={{ textAlign: 'center' }}>
                {ligne.ligne_donnee[0]}
              </td>
              <td key="total-servers-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
              <td key="total-servers-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
              <td></td>
            </>
          );
        }
      
        // Affichage normal pour les autres lignes de données
        return ligne.ligne_donnee.map((donnee, index) => {        
          // Fusionner Table et Heure sous 600px
          if (index === 0) {
            return (
              <td key={index} className={`${styles.tableHeureCell}`} style={{ textAlign: 'center' }}>
                <div>{ligne.ligne_donnee[0]}</div>
                <div style={{ borderTop: '1px solid #ccc', paddingTop: '4px' }}>
                  {ligne.ligne_donnee[1].substring(0, 5)}
                </div>
              </td>
            );
          }
        
          // Ne pas afficher la colonne Heure après fusion avec Table
          if (index === 1) {
            return null;
          }

          // Fusionner Prix et Type
          if (index === 5) {
            const prixTypeValue = `${ligne.ligne_donnee[5]} ${ligne.ligne_donnee[6]}`;
            return <td key={index} style={{ textAlign: 'center' }}>{prixTypeValue}</td>;
          }
    
          // Ne pas afficher la colonne Type après fusion avec Prix
          if (index === 6) {
            return null;
          }
        
          // Affichage conditionnel de la colonne raison pour le type annulation (rapport_id = 2)
          if (rapportfinancier.rapport_id === 2 && index === ligne.ligne_donnee.length - 1) {
            return <td key={index} style={{ textAlign: 'center' }}>{donnee}</td>;
          } else if (rapportfinancier.rapport_id !== 2 && index === ligne.ligne_donnee.length - 1) {
            // Ne pas afficher la colonne raison pour les autres types de rapport (offert et offert perso)
            return null;
          } else {
            return <td key={index} style={{ textAlign: 'center', borderTop: "1px solid #e0e0e0" }}>{donnee}</td>;
          }
        });
      } else {
        // Logique pour les écrans au-delà de 850px      
          // Gérer les lignes de totaux spécifiquement
          if (ligne.ligne_donnee[0] === 'Total annulations' || ligne.ligne_donnee[0] === 'Total offerts') {
            return (
              <>
                <td key="total-label" colSpan="4" className={styles.total} style={{ textAlign: 'center' }}>
                  {ligne.ligne_donnee[0]}
                </td>
                <td key="total-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
                <td key="total-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
                <td style={{ borderTop: "1px solid #e0e0e0" }}></td>
              </>
            );
          }
      
          if (ligne.ligne_donnee[0] === 'Total tous serveurs'){
            return (
              <>
                <td key="total-servers-label" className={styles.total} style={{ textAlign: 'center' }}>
                  {ligne.ligne_donnee[0]}
                </td>
                <td key="total-servers-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
                <td key="total-servers-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
              </>
            );
          }
      
          // Affichage normal pour les autres lignes de données
          return ligne.ligne_donnee.map((donnee, index) => {      
            // Formatage de l'heure au format hh:mm
            if (index === 1 && typeof donnee === 'string') {
              donnee = donnee.substring(0, 5);
            }

            // Fusionner Prix et Type
            if (index === 5) {
              const prixTypeValue = `${ligne.ligne_donnee[5]} ${ligne.ligne_donnee[6]}`;
              return <td key={index} style={{ textAlign: 'center' }}>{prixTypeValue}</td>;
            }
          
            // Ne pas afficher la colonne Type après fusion avec Prix
            if (index === 6) {
              return null;
            }
      
            // Affichage conditionnel de la colonne raison pour le type annulation (rapport_id = 2)
            if (rapportfinancier.rapport_id === 2 && index === ligne.ligne_donnee.length - 1) {
              return <td key={index} style={{ textAlign: 'center' }}>{donnee}</td>;
            } else if (rapportfinancier.rapport_id !== 2 && index === ligne.ligne_donnee.length - 1) {
              // Ne pas afficher la colonne raison pour les autres types de rapport (offert et offert perso)
              return null;
            } else {
              return <td key={index} style={{ textAlign: 'center', borderTop: "1px solid #e0e0e0" }}>{donnee}</td>;
            }
          });
      }
    }
  } else if (rapportfinancier.rapport_id == 3) {
    if (ligne.ligne_type == 'valeur') {
      if (isSmallScreen600) {
        // Gérer les lignes de totaux spécifiquement
        if (ligne.ligne_donnee[0] === 'Total annulations' || ligne.ligne_donnee[0] === 'Total offerts') {
          return (
            <>
              <td key="total-label" colSpan="2" className={styles.total} style={{ textAlign: 'center' }}>
                {ligne.ligne_donnee[0]}
              </td>
              <td key="total-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
              <td key="total-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
            </>
          );
        }
    
        if (ligne.ligne_donnee[0] === 'Total tous serveurs') {
          return (
            <>
              <td key="total-servers-label" colSpan="2" className={styles.total} style={{ textAlign: 'center' }}>
                {ligne.ligne_donnee[0]}
              </td>
              <td key="total-servers-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
              <td key="total-servers-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
            </>
          );
        }
    
        // Affichage normal pour les autres lignes de données
        return ligne.ligne_donnee.map((donnee, index) => {    
          // Fusionner Table et Heure sous 600px
          if (index === 0) {
            return (
              <td key={index} className={`${styles.tableHeureCell}`} style={{ textAlign: 'center' }}>
                <div>{ligne.ligne_donnee[0]}</div>
                <div style={{ borderTop: '1px solid #ccc', paddingTop: '4px' }}>
                  {ligne.ligne_donnee[1].substring(0, 5)}
                </div>
              </td>
            );
          }
    
          // Ne pas afficher la colonne Heure après fusion avec Table
          if (index === 1) {
            return null;
          }
    
          // Masquer la colonne Code sous 600px
          if (index === 2) {
            return (
              <td key={index} className={styles.mobileHide} style={{ textAlign: 'center' }}>
                {donnee}
              </td>
            );
          }
    
          // Fusionner Prix et Type
          if (index === 5) {
            const prixTypeValue = `${ligne.ligne_donnee[5]} ${ligne.ligne_donnee[6]}`;
            return <td key={index} style={{ textAlign: 'center' }}>{prixTypeValue}</td>;
          }
    
          // Ne pas afficher la colonne Type après fusion avec Prix
          if (index === 6) {
            return null;
          }
    
          // Affichage conditionnel de la colonne raison pour le type annulation (rapport_id = 2)
          if (rapportfinancier.rapport_id === 2 && index === ligne.ligne_donnee.length - 1) {
            return <td key={index} style={{ textAlign: 'center' }}>{donnee}</td>;
          } else if (rapportfinancier.rapport_id !== 2 && index === ligne.ligne_donnee.length - 1) {
            return null;
          } else {
            return <td key={index} style={{ textAlign: 'center' }}>{donnee}</td>;
          }
        });
      } else if (isSmallScreen850) {
        // Logique pour les écrans entre 600px et 850px
        if (ligne.ligne_donnee[0] === 'Total annulations' || ligne.ligne_donnee[0] === 'Total offerts') {
          return (
            <>
              <td key="total-label" colSpan="3" className={styles.total} style={{ textAlign: 'center' }}>
                {ligne.ligne_donnee[0]}
              </td>
              <td key="total-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
              <td key="total-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
            </>
          );
        }
      
        if (ligne.ligne_donnee[0] === 'Total tous serveurs') {
          return (
            <>
              <td key="total-servers-label" colSpan="2" className={styles.total} style={{ textAlign: 'center' }}>
                {ligne.ligne_donnee[0]}
              </td>
              <td key="total-servers-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
              <td key="total-servers-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
            </>
          );
        }
      
        // Affichage normal pour les autres lignes de données
        return ligne.ligne_donnee.map((donnee, index) => {        
          // Fusionner Table et Heure sous 600px
          if (index === 0) {
            return (
              <td key={index} className={`${styles.tableHeureCell}`} style={{ textAlign: 'center' }}>
                <div>{ligne.ligne_donnee[0]}</div>
                <div style={{ borderTop: '1px solid #ccc', paddingTop: '4px' }}>
                  {ligne.ligne_donnee[1].substring(0, 5)}
                </div>
              </td>
            );
          }
        
          // Ne pas afficher la colonne Heure après fusion avec Table
          if (index === 1) {
            return null;
          }

          // Fusionner Prix et Type
          if (index === 5) {
            const prixTypeValue = `${ligne.ligne_donnee[5]} ${ligne.ligne_donnee[6]}`;
            return <td key={index} style={{ textAlign: 'center' }}>{prixTypeValue}</td>;
          }
    
          // Ne pas afficher la colonne Type après fusion avec Prix
          if (index === 6) {
            return null;
          }
        
          // Affichage conditionnel de la colonne raison pour le type annulation (rapport_id = 2)
          if (rapportfinancier.rapport_id === 2 && index === ligne.ligne_donnee.length - 1) {
            return <td key={index} style={{ textAlign: 'center' }}>{donnee}</td>;
          } else if (rapportfinancier.rapport_id !== 2 && index === ligne.ligne_donnee.length - 1) {
            // Ne pas afficher la colonne raison pour les autres types de rapport (offert et offert perso)
            return null;
          } else {
            return <td key={index} style={{ textAlign: 'center' }}>{donnee}</td>;
          }
        });
      } else {
        // Logique pour les écrans au-delà de 850px      
          // Gérer les lignes de totaux spécifiquement
          if (ligne.ligne_donnee[0] === 'Total annulations' || ligne.ligne_donnee[0] === 'Total offerts') {
            return (
              <>
                <td key="total-label" colSpan="4" className={styles.total} style={{ textAlign: 'center' }}>
                  {ligne.ligne_donnee[0]}
                </td>
                <td key="total-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
                <td key="total-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
              </>
            );
          }
      
          if (ligne.ligne_donnee[0] === 'Total tous serveurs') {
            return (
              <>
                <td key="total-servers-label" colSpan="1" className={styles.total} style={{ textAlign: 'center' }}>
                  {ligne.ligne_donnee[0]}
                </td>
                <td key="total-servers-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
                <td key="total-servers-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
              </>
            );
          }
      
          // Affichage normal pour les autres lignes de données
          return ligne.ligne_donnee.map((donnee, index) => {      
            // Formatage de l'heure au format hh:mm
            if (index === 1 && typeof donnee === 'string') {
              donnee = donnee.substring(0, 5);
            }

            // Fusionner Prix et Type
            if (index === 5) {
              const prixTypeValue = `${ligne.ligne_donnee[5]} ${ligne.ligne_donnee[6]}`;
              return <td key={index} style={{ textAlign: 'center' }}>{prixTypeValue}</td>;
            }
          
            // Ne pas afficher la colonne Type après fusion avec Prix
            if (index === 6) {
              return null;
            }
      
            // Affichage conditionnel de la colonne raison pour le type annulation (rapport_id = 2)
            if (rapportfinancier.rapport_id === 2 && index === ligne.ligne_donnee.length - 1) {
              return <td key={index} style={{ textAlign: 'center' }}>{donnee}</td>;
            } else if (rapportfinancier.rapport_id !== 2 && index === ligne.ligne_donnee.length - 1) {
              // Ne pas afficher la colonne raison pour les autres types de rapport (offert et offert perso)
              return null;
            } else {
              return <td key={index} style={{ textAlign: 'center' }}>{donnee}</td>;
            }
          });
      }
    }
  } else if(rapport.rapport_id == 4){
        const screenSize = isSmallScreen655 
          ? "isSmallScreen655" 
          : isSmallScreen1024 
          ? "isSmallScreen1024" 
          : null;

        // Vérifier que ligne et ligne.ligne_donnee existent et sont valides
        if (!ligne || !ligne.ligne_donnee || ligne.ligne_donnee.length === 0) {
          return null;  // Ne rien rendre si les données sont manquantes ou invalides
        }
      
        const firstCellClass = `${styles.rapport4_table} ${styles.first_cell}`;

        // Filtrer les données en supprimant la dernière colonne uniquement pour les tailles d'écran spécifiques
        let filteredDonnees = ligne.ligne_donnee;
      
        if (screenSize === "isSmallScreen655" || screenSize === "isSmallScreen1024") {
          filteredDonnees = ligne.ligne_donnee.slice(0, -1);  // Supprimer la dernière colonne (Ratios)
        }
      
        if (ligne.ligne_type === 'valeur') {
          // Vérification spécifique pour la ligne "Total famille"
          if (ligne.ligne_donnee[0] === 'Total famille') {
            if(screenSize == "isSmallScreen655"){
              return (
                <>
                  <tr className={styles.rapport4_table}>
                  <td className={firstCellClass} colSpan={isSmallScreen655 ? 3 : ligne.ligne_donnee.length + 1}>
                    Total famille
                  </td>
                  </tr>
                  <tr className={styles.rapport4_table}>
                    {/* Protéger le rendu avec un fallback pour les valeurs manquantes */}
                        <th className={styles.rapport4_table}>
                          <div>{ligne.ligne_donnee[1]}</div> {/* T.T.C. */}
                          <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[3]}</div> {/* H.T./H.S. */}
                        </th>
                        <th className={styles.rapport4_table}>
                          <div>{ligne.ligne_donnee[2]}</div> {/* T.V.A. */}
                          <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[4]}</div> {/* Service */}
                        </th>
                        <th className={styles.rapport4_table}>{ligne.ligne_donnee[5]}</th>
                    </tr>
                </>
            );
          }else{
            return (
              <>
                <tr className={styles.rapport4_table}>
                  <td className={firstCellClass} colSpan={isSmallScreen655 ? 3 : ligne.ligne_donnee.length + 1}>
                    Total famille
                  </td>
                </tr>

                  <tr className={styles.rapport4_table}>
                  {!isSmallScreen655 && (
                    <td className={styles.rapport4_table} colSpan={2}>-</td>
                  )}
                  {/* Protéger le rendu avec un fallback pour les valeurs manquantes */}
                  {ligne.ligne_donnee.slice(1).map((donnee, index) => (
                    <td className={styles.rapport4_table} key={index}>
                      {donnee || '0'} {/* Si donnee est null ou undefined */}
                    </td>
                  ))}
                </tr>
                </>
              );
          }}
        
          // Pour toutes les autres lignes, vérifier le mode responsive et rendre les données
          if (rapports.cumul) {
            // Si rapports.cumul est vrai
            switch (screenSize) {
              case "isSmallScreen655":
                return (
                  <>
                    <tr className={styles.rapport4_table}>
                      <td className={firstCellClass}>{filteredDonnees[0] || '0'}</td>  {/* Code */}
                      <td className={firstCellClass} colSpan={2}>{filteredDonnees[1] || '0'}</td>  {/* Désignation */}
                    </tr>
                    <tr className={styles.rapport4_table}>
                      <th className={styles.rapport4_table}>
                        <div>{ligne.ligne_donnee[2]}</div> {/* T.T.C. */}
                        <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[4]}</div> {/* H.T./H.S. */}
                      </th>
                      <th className={styles.rapport4_table}>
                        <div>{ligne.ligne_donnee[3]}</div> {/* T.V.A. */}
                        <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[5]}</div> {/* Service */}
                      </th>
                      <th className={styles.rapport4_table}>{ligne.ligne_donnee[6]}</th>
                    </tr>
                  </>
                );
              case "isSmallScreen1024":
                return (
                  <tr className={styles.rapport4_table}>
                    <td className={firstCellClass}>{filteredDonnees[0] || '0'}</td>  {/* Code */}
                    <td className={styles.rapport4_table}>{filteredDonnees[1] || '0'}</td>  {/* Désignation */}
                    {filteredDonnees.slice(2).map((donnee, index) => (
                      <td className={styles.rapport4_table} key={index}>
                        {donnee || '0'}  {/* Protéger contre les données manquantes */}
                      </td>
                    ))}
                  </tr>
                );
              
              default:
                return (
                  <tr className={styles.rapport4_table}>
                    <td className={firstCellClass}>{ligne.ligne_donnee[0] || '0'}</td>  {/* Code */}
                    <td className={styles.rapport4_table}>{ligne.ligne_donnee[1] || '0'}</td>  {/* Désignation */}
                    {ligne.ligne_donnee.slice(2).map((donnee, index) => (
                      <td className={styles.rapport4_table} key={index}>
                        {donnee || '0'}  {/* Protéger contre les données manquantes */}
                      </td>
                    ))}
                  </tr>
                );
            }
          } else {
            // Si rapports.cumul est faux (ancien switch sans suppression de "Ratios")
            switch (screenSize) {
              case "isSmallScreen655":
                return (
                  <>
                    <tr className={styles.rapport4_table}>
                      <td className={firstCellClass}>{filteredDonnees[0] || '0'}</td>  {/* Code */}
                      <td className={firstCellClass} colSpan={2}>{filteredDonnees[1] || '0'}</td>  {/* Désignation */}
                    </tr>
                    <tr className={styles.rapport4_table}>
                      <th className={styles.rapport4_table}>
                        <div>{ligne.ligne_donnee[2]}</div> {/* T.T.C. */}
                        <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[4]}</div> {/* H.T./H.S. */}
                      </th>
                      <th className={styles.rapport4_table}>
                        <div>{ligne.ligne_donnee[3]}</div> {/* T.V.A. */}
                        <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[5]}</div> {/* Service */}
                      </th>
                      <th className={styles.rapport4_table}>{ligne.ligne_donnee[6]}</th>
                    </tr>
                  </>
                );
              
              
              case "isSmallScreen1024":
                return (
                  <tr className={styles.rapport4_table}>
                    <td className={firstCellClass}>{ligne.ligne_donnee[0] || '0'}</td>  {/* Code */}
                    <td className={styles.rapport4_table}>{ligne.ligne_donnee[1] || '0'}</td>  {/* Désignation */}
                    {ligne.ligne_donnee.slice(2).map((donnee, index) => (
                      <td className={styles.rapport4_table} key={index}>
                        {donnee || '0'}  {/* Protéger contre les données manquantes */}
                      </td>
                    ))}
                  </tr>
                );
              
              default:
                return (
                  <tr className={styles.rapport4_table}>
                    <td className={firstCellClass}>{ligne.ligne_donnee[0] || '0'}</td>  {/* Code */}
                    <td className={styles.rapport4_table}>{ligne.ligne_donnee[1] || '0'}</td>  {/* Désignation */}
                    {ligne.ligne_donnee.slice(2).map((donnee, index) => (
                      <td className={styles.rapport4_table} key={index}>
                        {donnee || '0'}  {/* Protéger contre les données manquantes */}
                      </td>
                    ))}
                  </tr>
                );
            }
          }           
        }
      } else if(rapport.rapport_id == 6){
        const firstCellClass = `${styles.rapport4_table} ${styles.first_cell}`;
      
        if (ligne.ligne_type === 'valeur') {            
          // Pour toutes les autres lignes, vérifier le mode responsive et rendre les données
          if (rapports.cumul) {
            if (ligne.ligne_donnee[0] !== 'Total') {
              const combinedValues = [];
              combinedValues.push(ligne.ligne_donnee[0]); // Première valeur, le numéro et le serveur
              combinedValues.push(ligne.ligne_donnee[1]); // Deuxième valeur, le total C.A.
            
              // Combinez chaque donnée numérique avec son pourcentage suivant
              for (let i = 2; i < ligne.ligne_donnee.length; i += 2) {
                if (i + 1 < ligne.ligne_donnee.length) {
                  combinedValues.push(`${ligne.ligne_donnee[i]} - ${ligne.ligne_donnee[i + 1]}`);
                } else {
                  combinedValues.push(ligne.ligne_donnee[i]); // Ajouter la dernière valeur si pas de pourcentage suivant
                }
              }
            
              return (
                <tr className={styles.rapport6_table}>
                  {combinedValues.map((value, index) => (
                    <td className={styles.rapport6_table} key={index}>
                      {value || '0'}
                    </td>
                  ))}
                </tr>
              );
            }

          } else {
            if(ligne.ligne_donnee[0] !== 'Total') {
            // Si rapports.cumul est faux (ancien switch sans suppression de "Ratios")
              return (
                <tr className={styles.rapport4_table}>
                  <td className={firstCellClass}>{ligne.ligne_donnee[0] || '0'}</td>
                  <td className={styles.rapport4_table}>{ligne.ligne_donnee[1] || '0'}</td>
                  {ligne.ligne_donnee.slice(2).map((donnee, index) => (
                    <td className={styles.rapport4_table} key={index}>
                      {donnee || '0'}
                    </td>
                  ))}
                </tr>
              );
            }
          }           
        }
      } else if (rapportfinancier.rapport_id == 7) {
        // Body tableau pour le rapport offert perso
        if (ligne.ligne_type == 'valeur') {
          if (isSmallScreen600) {
            // Gérer les lignes de totaux spécifiquement
            if (ligne.ligne_donnee[0] === 'Total offerts') {
              return (
                <>
                  <td key="total-label" colSpan="2" className={styles.total} style={{ textAlign: 'center' }}>
                    {ligne.ligne_donnee[0]}
                  </td>
                  <td key="total-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
                  <td key="total-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
                </>
              );
            }
          
            if (ligne.ligne_donnee[0] === 'Total tous serveurs') {
              return (
                <>
                  <td key="total-servers-label" colSpan="2" className={styles.total} style={{ textAlign: 'center' }}>
                    {ligne.ligne_donnee[0]}
                  </td>
                  <td key="total-servers-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
                  <td key="total-servers-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
                </>
              );
            }
          
            // Affichage normal pour les autres lignes de données
            return ligne.ligne_donnee.map((donnee, index) => {        
              // Fusionner Table et Heure sous 600px
              if (index === 0) {
                return (
                  <td key={index} className={`${styles.tableHeureCell}`} style={{ textAlign: 'center' }}>
                    <div>{ligne.ligne_donnee[0]}</div>
                    <div style={{ borderTop: '1px solid #ccc', paddingTop: '4px' }}>
                      {ligne.ligne_donnee[1].substring(0, 5)}
                    </div>
                  </td>
                );
              }
    
              // Ne pas afficher la colonne Heure après fusion avec Table
              if (index === 1) {
                return null;
              }
            
              // Masquer la colonne Code sous 600px
              if (index === 2) {
                return (
                  <td key={index} className={styles.mobileHide} style={{ textAlign: 'center' }}>
                    {donnee}
                  </td>
                );
              }
    
              // Fusionner Prix et Type
              if (index === 5) {
                const prixTypeValue = `${ligne.ligne_donnee[5]} ${ligne.ligne_donnee[6]}`;
                return <td key={index} style={{ textAlign: 'center' }}>{prixTypeValue}</td>;
              }
            
              // Ne pas afficher la colonne Type après fusion avec Prix
              if (index === 6) {
                return null;
              }
            
              // Ignorer la colonne raison pour le type offert perso
              if (index === ligne.ligne_donnee.length - 1) {
                return null;
              } else {
                return <td key={index} style={{ textAlign: 'center' }}>{donnee}</td>;
              }
            });
          } else if (isSmallScreen850) {
            // Logique pour les écrans entre 600px et 850px
            // Body tableau pour le rapport offert perso
            if (ligne.ligne_type == 'valeur') {
              // Gérer les lignes de totaux spécifiquement
              if (ligne.ligne_donnee[0] === 'Total offerts') {
                return (
                  <>
                    <td key="total-label" colSpan="3" className={styles.total} style={{ textAlign: 'center' }}>
                      {ligne.ligne_donnee[0]}
                    </td>
                    <td key="total-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
                    <td key="total-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
                  </>
                );
              }
            
              if (ligne.ligne_donnee[0] === 'Total tous serveurs') {
                return (
                  <>
                    <td key="total-servers-label" colSpan="2" className={styles.total} style={{ textAlign: 'center' }}>
                      {ligne.ligne_donnee[0]}
                    </td>
                    <td key="total-servers-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
                    <td key="total-servers-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
                  </>
                );
              }
            
              // Affichage normal pour les autres lignes de données
              return ligne.ligne_donnee.map((donnee, index) => {
                // Fusionner Table et Heure sous 600px
                if (index === 0) {
                  return (
                    <td key={index} className={`${styles.tableHeureCell}`} style={{ textAlign: 'center' }}>
                      <div>{ligne.ligne_donnee[0]}</div>
                      <div style={{ borderTop: '1px solid #ccc', paddingTop: '4px' }}>
                        {ligne.ligne_donnee[1].substring(0, 5)}
                      </div>
                    </td>
                  );
                }
      
                // Ne pas afficher la colonne Heure après fusion avec Table
                if (index === 1) {
                  return null;
                }
              
                // Fusionner Prix et Type
                if (index === 5) {
                  const prixTypeValue = `${ligne.ligne_donnee[5]} ${ligne.ligne_donnee[6]}`;
                  return <td key={index} style={{ textAlign: 'center' }}>{prixTypeValue}</td>;
                }
              
                // Ne pas afficher la colonne Type après fusion avec Prix
                if (index === 6) {
                  return null;
                }
                
              
                // Ignorer la colonne raison pour le type offert perso
                if (index === ligne.ligne_donnee.length - 1) {
                  return null;
                } else {
                  return <td key={index} style={{ textAlign: 'center' }}>{donnee}</td>;
                }
              });
            }
          } else {
            // Logique pour les écrans au-delà de 850px
            // Body tableau pour le rapport offert perso
            if (ligne.ligne_type == 'valeur') {
            
              if (ligne.ligne_donnee[0] === 'Total offerts') {
                return (
                  <>
                    <td key="total-label" colSpan="4" className={styles.total} style={{ textAlign: 'center' }}>
                      {ligne.ligne_donnee[0]}
                    </td>
                    <td key="total-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
                    <td key="total-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
                  </>
                );
              }
            
              if (ligne.ligne_donnee[0] === 'Total tous serveurs') {
                return (
                  <>
                    <td key="total-servers-label" colSpan="1" className={styles.total} style={{ textAlign: 'center' }}>
                      {ligne.ligne_donnee[0]}
                    </td>
                    <td key="total-servers-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
                    <td key="total-servers-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
                  </>
                );
              }
            
              // Gestion des autres lignes de données
              return ligne.ligne_donnee.map((donnee, idx) => {
                if (idx === 1 && typeof donnee === 'string') { // Formatage de l'heure
                  donnee = donnee.substring(0, 5);
                }
            
                if (idx === 5) { // Fusionner Prix et Type
                  const prixTypeValue = `${donnee} ${ligne.ligne_donnee[6]}`;
                  return <td key={idx} style={{ textAlign: 'center' }}>{prixTypeValue}</td>;
                }
            
                if (idx === 6) { // Ne pas afficher la colonne Type après fusion avec Prix
                  return null;
                }

                if (idx === 7){
                  return null;
                }
            
                // Affichage normal pour les autres colonnes
                return <td key={idx} style={{ textAlign: 'center' }}>{donnee}</td>;
              });
            }                        
          }
        }
      } else {

        if(ligne.ligne_type == 'valeur') {

          return (ligne.ligne_donnee.map((donnee, index) =>
            <td id={index}>{donnee}</td>
          ))

        }

      }
    return null;
  };

  const renderTabAnnulation = (ligne, titreDonnees) => {
    // S'assurer que les données ne sont pas manquantes ou invalides
    if (!ligne || !ligne.ligne_donnee || ligne.ligne_donnee.length === 0) {
      return null; // Ne rien rendre si les données sont manquantes ou invalides
    }

    // Vérifier si 'rapports.cumul' est vrai et si la première donnée n'est pas 'Total'
    if (ligne.ligne_type === 'valeur') {
      if (rapports.cumul){
        if(ligne.ligne_donnee[0] !== 'Total') {
          const valeursCombinees = [];
          valeursCombinees.push(ligne.ligne_donnee[0]); // Première valeur, le numéro et le serveur
          valeursCombinees.push(ligne.ligne_donnee[1]); // Deuxième valeur, le total C.A.
          for (let i = 2; i < ligne.ligne_donnee.length; i += 2) {
            valeursCombinees.push(`${ligne.ligne_donnee[i]} - ${ligne.ligne_donnee[i + 1]}`);
          }
        
          // Création du tableau avec les titres et les valeurs combinées
          return (
            <table className={styles.rapport4_table_individual}>
              <tbody>
                {titreDonnees.map((titre, index) => (
                  <tr key={index}>
                    <td className={styles.titreCell}>{titre}</td>
                    <td className={styles.valeurCell}>{valeursCombinees[index]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          );
        }
      } else {
        if(ligne.ligne_donnee[0] !== 'Total') {
          // Si rapports.cumul est faux (ancien switch sans suppression de "Ratios")
          return (
            null
          );
        }
      }           
    }
  }


  const renderTitle = () => {
    if (rapports.cumul == true) {
      return <h2><b>{rapportfinancier.rapport_nom}</b> <b>du</b> {cumul.date_min_affichage} <b>au</b> {cumul.date_max_affichage}</h2>  
    } else {
      const date = format(Date.parse(service[0].date_service), 'EEEE dd MMMM yyyy', {locale: fr})
      return <h2><b>{rapportfinancier.rapport_nom}</b> Service n°{service[0].num_service} <b>du</b> {date}</h2>   
    }
  }

  const renderNumtabres = () => {
    if (rapports.cumul == false && rapport.rapport_id == 5) {
      return <h2>BANDE CONTRÔLE N°{service[0].num_tab_res_affichage}</h2>   
    } 
  }

  const renderChargement = () => {
    if(hasMore == true && hasLess == true && pause == false) {
      return (
        <div className={styles.spinner}>
          <Spinner className={styles.spinner} variant="dark" animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )
    } 
  }

  const renderRapportFinancier = () => {
    switch (rapportfinancier.rapport_id) {
      case 1:
        return (
          <div ref={componentRef}>
            <div className={styles.rapport4_titre_section}>
              <div>{renderTitle()}</div>
              <div className={styles.numtabres_rapport4}>{renderNumtabres()}</div>
              <Button className={styles.bouton_imprimer_rapport4} variant="secondary" onClick={handlePrint}>
                Imprimer
              </Button>
            </div>
            
            {rapportfinancier.sections[0] && (
              <div className={styles.section_rapport4} key={rapportfinancier.sections[0].section_nom}>
                <h3 className={styles.group_title}>{rapportfinancier.sections[0].section_nom}</h3>
              </div>
            )}

            {/* Totaux par groupe */}
            <div className={styles.rapport4_table_wrapper}>
              <table className={styles.rapport4_table}>
                <thead>
                  {rapportfinancier.sections[0].tableaux[0].lignes.map((ligne, index) => (
                    <React.Fragment key={index}>
                      {renderHeaderTotaux(ligne, isSmallScreen655, rapportfinancier.sections[0].tableaux[0].tableau_id)} {/* Header spécifique pour `rapport_id` 1 */}
                    </React.Fragment>
                  ))}
                </thead>
                <tbody>
                  {rapportfinancier.sections[0].tableaux[0].lignes.map((ligne, index) => (
                    <React.Fragment key={index}>
                      {renderBodyTotaux(ligne, isSmallScreen655, rapportfinancier.sections[0].tableaux[0].tableau_id)} {/* Body spécifique pour `rapport_id` 1 */}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            {/* détails des encaissements Totaux*/}
            <div className={styles.rapport4_table_wrapper}>
              <table className={styles.rapport4_table}>
                <thead>
                  {rapportfinancier.sections[0].tableaux[1].lignes.map((ligne, index) => (
                    <React.Fragment key={index}>
                      {renderHeaderTotaux(ligne, isSmallScreen655, rapportfinancier.sections[0].tableaux[1].tableau_id)} {/* Header du tableau */}
                    </React.Fragment>
                  ))}
                </thead>
                <tbody>
                  {rapportfinancier.sections[0].tableaux[1].lignes.map((ligne, index) => (
                    <React.Fragment key={index}>
                      {renderBodyTotaux(ligne, isSmallScreen655, rapportfinancier.sections[0].tableaux[1].tableau_id)} {/* Body du tableau */}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            {/* Tableaux par Famille */}
            {rapportfinancier.sections.slice(1).map((section) => (
              <div className={styles.section_rapport4} key={section.section_nom}>
                <h3 className={styles.group_title}>{section.section_nom}</h3>
                <div className={styles.rapport4_table_wrapper}>
                  {section.tableaux.map((tableau, tableauIndex) => (
                    <div className={styles.rapport4_table} key={tableauIndex}>
                      <table className={styles.rapport4_table}>
                        <caption>{tableau.tableau_nom}</caption>
                        <thead>
                          {tableau.lignes.map((ligne, index) => (
                            <React.Fragment key={index}>
                              {renderHeaderFamille(ligne, isSmallScreen655, isSmallScreen1024, tableauIndex)} {/* Header pour les tableaux de Famille */}
                            </React.Fragment>
                          ))}
                        </thead>
                        <tbody>
                          {tableau.lignes.map((ligne, index) => (
                            <React.Fragment key={index}>
                              {renderBodyFamille(ligne, isSmallScreen655, isSmallScreen1024, tableauIndex, rapportfinancier.sections[0].tableaux)} {/* Body pour les tableaux de Famille */}
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        );
      case 4:
        return (
          <div ref={componentRef}>
            <div className={styles.rapport4_titre_section}>
              <div>{renderTitle()}</div>
              <div className={styles.numtabres_rapport4}>{renderNumtabres()}</div>
              <Button className={styles.bouton_imprimer_rapport4} variant="secondary" onClick={handlePrint}>
                Imprimer
              </Button>
            </div>
            {rapportfinancier.sections[0] && (
              <div className={styles.section_rapport4} key={rapportfinancier.sections[0].section_nom}>
                <h3 className={styles.group_title}>{rapportfinancier.sections[0].section_nom}</h3>
              </div>
            )}
            {/* Totaux par groupe */}
            <div className={styles.rapport4_table_wrapper}>
              <table className={styles.rapport4_table}>
                <thead>
                  {rapportfinancier.sections[0].tableaux[0].lignes.map((ligne, index) => (
                    <React.Fragment key={index}>
                      {renderHeaderTotaux(ligne, isSmallScreen655)} {/* Header pour Totaux */}
                    </React.Fragment>
                  ))}
                </thead>
                <tbody>
                  {rapportfinancier.sections[0].tableaux[0].lignes.map((ligne, index) => (
                    <React.Fragment key={index}>
                      {renderBodyTotaux(ligne, isSmallScreen655)} {/* Body pour Totaux */}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
  
            {/* Tableaux par Famille */}
            {rapportfinancier.sections.slice(1).map((section) => (
              <div className={styles.section_rapport4} key={section.section_nom}>
                <h3 className={styles.group_title}>{section.section_nom}</h3>
                <div className={styles.rapport4_table_wrapper}>
                  {section.tableaux.map((tableau, tableauIndex) => (
                    <div className={styles.rapport4_table} key={tableauIndex}>
                      <table className={styles.rapport4_table}>
                        <caption>{tableau.tableau_nom}</caption>
                        <thead>
                          {tableau.lignes.map((ligne, index) => (
                            <React.Fragment key={index}>
                              {renderHeaderFamille(ligne, isSmallScreen655, isSmallScreen1024)} {/* Header pour Famille */}
                            </React.Fragment>
                          ))}
                        </thead>
                        <tbody>
                          {tableau.lignes.map((ligne, index) => (
                            <React.Fragment key={index}>
                              {renderBodyFamille(ligne, isSmallScreen655, isSmallScreen1024)} {/* Body pour Famille */}
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        );
        case 6:
          return (
            <div ref={componentRef}>
              <div className={styles.rapport4_titre_section}>
                <div>{renderTitle()}</div>
                <div className={styles.numtabres_rapport4}>{renderNumtabres()}</div>
                <Button className={styles.bouton_imprimer_rapport4} variant="secondary" onClick={handlePrint}>
                  Imprimer
                </Button>
              </div>
        
              {rapportfinancier.sections.map((section) => (
                <div className={styles.section_rapport4} key={section.section_nom}>
                  <h3 className={styles.group_title}>{section.section_nom}</h3>
                  <div className={styles.rapport4_table_wrapper}>
                    {section.tableaux.map((tableau, tableauIndex) => (
                      <div className={styles.rapport4_table} key={tableauIndex}>
                        {isSmallScreen655 ? 
                          tableau.lignes.map((ligne, index) => (
                            <div key={index} className={styles.rapport4_table_individual_wrapper}>
                              {renderTabAnnulation(ligne, titreDonnees)}
                            </div>
                          )) :
                          <table className={styles.rapport4_table}>
                            <tbody>
                              {tableau.lignes.map((ligne, index) => (
                                <React.Fragment key={index}>
                                  {renderHeaderFamille(ligne, isSmallScreen655, isSmallScreen1024)}
                                  {renderBodyFamille(ligne, isSmallScreen655, isSmallScreen1024)}
                                </React.Fragment>
                              ))}
                            </tbody>
                          </table>
                        }
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          );
        
        case 2:
          return (
            <div ref={componentRef}>
              <div className={styles.grille_titre}>
                <div>{renderTitle()}</div>
                <div className={styles.numtabres}>{renderNumtabres()}</div>
                <Button className={styles.bouton_imprimer} variant="secondary" onClick={handlePrint}>
                  Imprimer
                </Button>
              </div>
              
              {rapportfinancier.sections.map((section) => (
                <div className={styles.section_titre} key={section.section_nom}>
                  <div>
                  <div className={styles.annotationContainer}>
                      <div className={styles.annotationLabelTitre}><b>Facture:</b></div>
                      <div className={styles.annotationItem}>
                          <div className={styles.annotationLabel}>Avant factures :</div>
                          <div className={styles.annotationValue}>-</div>
                      </div>
                      <div className={styles.annotationItem}>
                          <div className={styles.annotationLabel}>Après factures :</div>
                          <div className={styles.annotationValue}>AF</div>
                      </div>
                      <div className={styles.annotationItem}>
                          <div className={styles.annotationLabel}>Après encaissements :</div>
                          <div className={styles.annotationValue}>AE</div>
                      </div>
                  </div>
                </div>
                  <h3 className={styles.group_title}>{section.section_nom}</h3>
                  <div className={styles.grille_tableau}>
                    {section.tableaux.map((tableau, tableauIndex) => {
                      // Condition pour filtrer: affiche tous les tableaux sauf le dernier seulement s'ils ont plus de deux lignes
                      const shouldDisplay = tableauIndex === section.tableaux.length - 1 || tableau.lignes.length > 2;
                      const isLastTable = tableauIndex === section.tableaux.length - 1; // Vérifie si c'est le dernier tableau

                      return shouldDisplay ? (
                        <div className={styles.tableau} key={tableau.tableau_nom}>
                          <table className={styles.rapport4_table}>
                            <caption>{tableau.tableau_nom}</caption>
                            <thead>
                              {tableau.lignes.some(ligne => ligne.ligne_type === 'titre') && !isLastTable ? (
                                tableau.lignes.map((ligne, index) =>
                                  ligne.ligne_type === 'titre' ? (
                                    <React.Fragment key={index}>
                                      {renderHeaderFamille(ligne, isSmallScreen655, isSmallScreen1024, isSmallScreen600, isSmallScreen850)}
                                    </React.Fragment>
                                  ) : null
                                )
                              ) : (
                                // Header pour le dernier tableau
                                <tr style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                                  {Array.from({ length: isSmallScreen600 ? 6 : isSmallScreen850 ? 6 : 7 }).map((_, idx) => (
                                    <th key={idx} style={{
                                      backgroundColor: '#262626',
                                      textAlign: 'center',
                                      color: '#262626',
                                    }}>Qte</th>
                                  ))}
                                </tr>
                              )}
                            </thead>
                            <tbody>
                              {tableau.lignes.map((ligne, index) => (
                                <tr key={index}>
                                  {renderBodyFamille(ligne, isSmallScreen655, isSmallScreen1024, isSmallScreen600, isSmallScreen850)}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
              ))}
            </div>
          );
          
        case 3:
        case 7:
          return (
            <div ref={componentRef}>
              <div className={styles.grille_titre}>
                <div>{renderTitle()}</div>
                <div className={styles.numtabres}>{renderNumtabres()}</div>
                <Button className={styles.bouton_imprimer} variant="secondary" onClick={handlePrint}>
                  Imprimer
                </Button>
              </div>
              
              {rapportfinancier.sections.map((section) => (
                <div className={styles.section_titre} key={section.section_nom}>
                  <div>
                  <div className={styles.annotationContainer}>
                      <div className={styles.annotationLabelTitre}><b>Facture:</b></div>
                      <div className={styles.annotationItem}>
                          <div className={styles.annotationLabel}>Avant factures :</div>
                          <div className={styles.annotationValue}>-</div>
                      </div>
                      <div className={styles.annotationItem}>
                          <div className={styles.annotationLabel}>Après factures :</div>
                          <div className={styles.annotationValue}>AF</div>
                      </div>
                      <div className={styles.annotationItem}>
                          <div className={styles.annotationLabel}>Après encaissements :</div>
                          <div className={styles.annotationValue}>AE</div>
                      </div>
                  </div>
                </div>
                  <h3 className={styles.group_title}>{section.section_nom}</h3>
                  <div className={styles.grille_tableau}>
                    {section.tableaux.map((tableau, tableauIndex) => {
                      // Condition pour filtrer: affiche tous les tableaux sauf le dernier seulement s'ils ont plus de deux lignes
                      const shouldDisplay = tableauIndex === section.tableaux.length - 1 || tableau.lignes.length > 2;
                      const isLastTable = tableauIndex === section.tableaux.length - 1; // Vérifie si c'est le dernier tableau

                      return shouldDisplay ? (
                        <div className={styles.tableau} key={tableau.tableau_nom}>
                          <table className={styles.rapport4_table}>
                            <caption>{tableau.tableau_nom}</caption>
                            <thead>
                              {tableau.lignes.some(ligne => ligne.ligne_type === 'titre') && !isLastTable ? (
                                tableau.lignes.map((ligne, index) =>
                                  ligne.ligne_type === 'titre' ? (
                                    <React.Fragment key={index}>
                                      {renderHeaderFamille(ligne, isSmallScreen655, isSmallScreen1024, isSmallScreen600, isSmallScreen850)}
                                    </React.Fragment>
                                  ) : null
                                )
                              ) : (
                                // Header pour le dernier tableau
                                <tr style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                                  {Array.from({ length: isSmallScreen600 ? 4 : isSmallScreen850 ? 6 : 6 }).map((_, idx) => (
                                    <th key={idx} style={{
                                      backgroundColor: '#262626',
                                      textAlign: 'center',
                                      color: '#262626',
                                    }}>Qte</th>
                                  ))}
                                </tr>
                              )}
                            </thead>
                            <tbody>
                              {tableau.lignes.map((ligne, index) => (
                                <tr key={index}>
                                  {renderBodyFamille(ligne, isSmallScreen655, isSmallScreen1024, isSmallScreen600, isSmallScreen850)}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
              ))}
            </div>
          );
        
        default:
          // Cas par défaut : afficher un message ou une structure générique si rapport_id n'est pas 4
          return (
            <div ref={componentRef}>
              <div className={styles.grille_titre}>
                <div>{renderTitle()}</div>
                <div className={styles.numtabres}>{renderNumtabres()}</div>
                <Button className={styles.bouton_imprimer} variant="secondary" onClick={handlePrint}>
                  Imprimer
                </Button>
              </div>
          
              {/* Sections et tableaux génériques */}
              {rapportfinancier.sections.map((section) => (
                <div className={styles.section_titre} key={section.section_nom}>
                  <h3 className={styles.group_title}>{section.section_nom}</h3>
                  <div className={styles.grille_tableau}>
                    {section.tableaux.map((tableau, tableauIndex) => (
                      <div className={styles.tableau} key={tableau.tableau_nom}>
                        <table className={styles.rapport4_table}>
                          <caption>{tableau.tableau_nom}</caption>
                          <thead>
                            {tableau.lignes.map((ligne, index) => (
                              <React.Fragment key={index}>
                                {renderHeaderFamille(ligne, isSmallScreen655, isSmallScreen1024, isSmallScreen600, isSmallScreen850)} {/* Header générique */}
                              </React.Fragment>
                            ))}
                          </thead>
                          <tbody>
                            {tableau.lignes.map((ligne, index) => (
                              <tr key={index}>
                                {renderBodyFamille(ligne, isSmallScreen655, isSmallScreen1024, isSmallScreen600, isSmallScreen850)} {/* Body générique */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          );
    }
  };
  
  
  const renderBandeControleList = () => {
    if (rapports.cumul == true) { 
      return (
          <div className={styles.bande} >
          {
            rapportfinancier.sections.map((section, index) => 
              <div key={index} className={styles.document}>
                {
                  section.entete.map((entete) =>
                    <div>{entete}</div>      
                  )
                }
                <div className={styles.document_entete}>
                  {
                    section.commande.map((commande) =>
                    
                      <div className={styles.document_commande}>
                        {commande.map((element) => 
                          <div>{element}</div>
                        )}
                      </div> 
                    )
                  }
                </div>
                <div className={styles.document_entete}>
                  {
                    section.totaux.map((totaux) =>
                    
                      <div className={styles.document_totaux}>
                        {totaux.map((element) => 
                          <div>{element}</div>
                        )}
                      </div>      
                    )
                  }
                </div>
                <div className={styles.document_entete}>
                  {
                    section.reglement.map((reglement) =>
                    
                      <div className={styles.document_totaux}>
                        {reglement.map((element) => 
                          <div>{element}</div>
                        )}
                      </div> 
                    )
                  }
                </div>
              </div>)
          }  
        </div>
        )
    } else {
      return (
        <div  className={styles.bande}>
        {
          rapportfinancier.sections.map((section) => 
            <div className={styles.document}>
              {
              section.entete.map((entete) =>
                <div>{entete}</div>      
              )
              }
              <div className={styles.document_entete}>
                {
                  section.commande.map((commande) =>
                  
                    <div className={styles.document_commande}>
                      {commande.map((element) => 
                        <div>{element}</div>
                      )}
                    </div>      
                  )
                }
              </div>
              <div className={styles.document_entete}>
                {
                  section.totaux.map((totaux) =>
                  <div className={styles.document_totaux}>
                    {totaux.map((element) => 
                      <div>{element}</div>
                    )}
                  </div>      
                
                  )
                }
              </div>
              <div className={styles.document_entete}>
                {
                  section.reglement.map((reglement) =>
                  <div className={styles.document_totaux}>
                    {reglement.map((element) => 
                      <div>{element}</div>
                    )}
                  </div>      
                  )
                }
              </div>
            </div>)
        }
      </div>)
    }
  }

  const renderEtatChargement = () => {
    if(hasMore == true && finBandeControle == false && pause == false) 
      return <Button className={styles.bouton_etat} variant="secondary" onClick={fonctionPause}>Pause</Button>
    else if(pause == true && finBandeControle == false)
      return <Button className={styles.bouton_etat} variant="secondary" onClick={fonctionReprise}>Reprise</Button>
  }

  const renderBandeControle = () => {
    return (
      <div ref={componentRef} >
        <div className={styles.grille_titre}>
          <div>{renderTitle()}</div>
          {renderChargement()}
          {renderEtatChargement()}
          <Button className={styles.bouton_imprimer} variant="secondary" onClick={handlePrint}>Imprimer</Button>
        </div>
        {renderBandeControleList()}
      </div>
    )
  }

  const renderRapport = () => {
    if (loading) {
        return (
            <div className={styles.rapportAlertWrapper}>
                <Alert variant='secondary'>Chargement du rapport</Alert>
            </div>
        );
    }
    if (hasErrors) {
        return (
            <div className={styles.rapportAlertWrapper}>
                <Alert variant='danger'>Impossible de charger le rapport</Alert>
            </div>
        );
    }
    if (donnee === false) {
        return (
            <div className={styles.rapportAlertWrapper}>
                <Alert variant='warning'>Aucune donnée dans la période sélectionnée</Alert>
            </div>
        );
    }
    if (loaded) {
        return (
            <div>
                {rapportfinancier.rapport_id === 5 ? renderBandeControle() : renderRapportFinancier()}
            </div>
        );
    }
    // Par défaut, ne rien afficher
    return null;
  };
  

  return (
    <div className={styles.rapport_wrapper}>
      <div className={styles.div_rapport_list}>
        <div className={styles.rapport_list}>
          {renderRapportList()}
        </div>
      </div>
      <div className={styles.div_rapport}>
        {renderRapport()}
      </div>
    </div>
  )
}