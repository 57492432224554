import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchDeconnexion } from "../connexion/connexionSlice";
import { API_URL } from "../../Constants";
import axios from "axios";

export const initialState = {
  loading: false,
  hasErrors: false,
  loaded: false,
  type: "",
  dashboard: [],
  live: {
    titres: [],
    designations: [],
    couverts: {
      enCours: [0,0],
      realise: [0,0],
    },
    ca: {
      enCours: [0,0],
      realise: [0,0],
    },
    totals: {
      CVTotal: 0,
      TotalCA: 0,
    },
    dates: {
      serviceEnCours: null,
      finServiceEnCours: null,
      heureFinServiceEnCours: null,
    },
    etatDuService: null,
  },
};


const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    getDashboard: (state) => {
      state.loading = true;
      state.loaded = false;
    },
    getDashboardSuccess: (state, { payload }) => {
      state.dashboard = payload;
      state.loading = false;
      state.hasErrors = false;
      state.loaded = true;
    },
    getLiveSuccess: (state, { payload }) => {
      state.live = payload; // Mise à jour des données live
      state.loading = false;
      state.hasErrors = false;
      state.loaded = true;
    },
    getDashboardFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
      state.loaded = false;
    },
    setType: (state, { payload }) => {
      state.type = payload;
    },
  },
});

export const {
  getDashboard,
  getDashboardSuccess,
  getLiveSuccess,
  getDashboardFailure,
  setType,
} = dashboardSlice.actions;

export const dashboardSelector = (state) => state.dashboard;
export const liveSelector = (state) => state.dashboard.live; // Sélecteur pour les données live

export default dashboardSlice.reducer;

// Fonction pour l'API principale (fetchDashboard)
export function fetchDashboard(type, date_min = 0, date_max = 0) {
  return async (dispatch, getState) => {
    const restaurant_choisi = getState().connexion.restaurant_choisi;

    dispatch(getDashboard());
    dispatch(setType(type));

    try {
      const config = {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      };

      const response = await axios.get(
        `${API_URL}/web/dashboard/${type}/${date_min}/${date_max}/${restaurant_choisi}`,
        config
      );
      
      dispatch(getDashboardSuccess(response.data));
      return response.data; // Retourne les données
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(fetchDeconnexion());
      }
      dispatch(getDashboardFailure());
    }
  };
}

const defaultValue = (value, defaultVal = 0) => {
  return value !== undefined && value !== null ? value : defaultVal;
};


// Fonction pour l'API live (fetchLive)
export function fetchLive(type, date_min = 0, date_max = 0) {
  return async (dispatch, getState) => {
    const restaurant_choisi = getState().connexion.restaurant_choisi;

    dispatch(getDashboard());
    dispatch(setType(type));

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache, no-store, must-revalidate",
          Pragma: "no-cache",
          Expires: "0",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${API_URL}/web/dashboard/live/${type}/${date_min}/${date_max}/${restaurant_choisi}?t=${new Date().getTime()}`,
        config
      );

      const data = response.data;

      // Transformation des données
      const titres = data[0]?.ligne_donnee || []; // Titres des colonnes
      const designations = data[1]?.ligne_donnee || []; // Titres des lignes

      // Tickets et couverts
      const couvertsBarEnCours = defaultValue(data[2]?.NbTicketBar);
      const couvertsRestoEnCours = defaultValue(data[2]?.NbCouvert);
      const couvertsBarRealise = defaultValue(data[3]?.NbTicketBar);
      const couvertsRestoRealise = defaultValue(data[3]?.NbCouvert);

      // CA
      const caBrasserieEnCours = defaultValue(data[2]?.["CA.Brasserie"]);
      const caRestaurantEnCours = defaultValue(data[2]?.["CA.Restaurant"]);
      const caBrasserieRealise = defaultValue(data[3]?.["CA.Brasserie"]);
      const caRestaurantRealise = defaultValue(data[3]?.["CA.Restaurant"]);

      const CAenCours = defaultValue(data[2]?.["CA.Brasserie"]) + defaultValue(data[2]?.["CA.Restaurant"]);
      const CArealise = defaultValue(data[3]?.["CA.Brasserie"]) + defaultValue(data[3]?.["CA.Restaurant"]);

      // CV
      const CVenCours = defaultValue(data[2]?.["NbCouvert"]) + defaultValue(data[2]?.["NbTicketBar"]);
      const CVrealise = defaultValue(data[3]?.["NbCouvert"]) + defaultValue(data[3]?.["NbTicketBar"]);

      // Totaux
      const CVTotal = CVenCours + CVrealise;
      const TotalCA = CAenCours + CArealise;

      // Dates
      const dateServiceEnCours = data[2]?.DateService || null;
      const dateFinServiceEnCours = data[2]?.DateFinService || null;
      const heureFinServiceEnCours = data[2]?.HeureFinService || null;

      // État du service
      const etatDuService =
        data[2]?.["impr_edit"] !== undefined && data[2]?.["impr_edit"] !== null
          ? data[2]["impr_edit"]
          : defaultValue(data[3]?.["impr_edit"], null);

      // Restructuration de liveData en un objet avec des clés explicites
      const liveData = {
        titres: titres,
        designations: designations,
        couverts: {
          enCours: [couvertsBarEnCours, couvertsRestoEnCours],
          realise: [couvertsBarRealise, couvertsRestoRealise],
        },
        ca: {
          enCours: [caBrasserieEnCours, caRestaurantEnCours],
          realise: [caBrasserieRealise, caRestaurantRealise],
        },
        totals: {
          CVTotal,
          TotalCA,
        },
        dates: {
          serviceEnCours: dateServiceEnCours,
          finServiceEnCours: dateFinServiceEnCours,
          heureFinServiceEnCours: heureFinServiceEnCours,
        },
        etatDuService: etatDuService,
      };

      dispatch(getLiveSuccess(liveData));
      return liveData; // Retourne les données
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(fetchDeconnexion());
      }
      dispatch(getDashboardFailure());
    }
  };
}
