import React, { useState, useEffect, Suspense, lazy } from "react";
import logo from "./logo.svg";
import { Counter } from "./features/counter/Counter";
import { Menu } from "./features/menu/Menu";
import { Rapports } from "./features/rapports/Rapports";
import { Dashboard } from "./features/dashboard/Dashboard";
import { Services } from "./features/services/Services";
import { RapportFinancier } from "./features/rapportfinancier/RapportFinancier";
import { Connexion } from "./features/connexion/Connexion";
import { Cumul } from "./features/cumul/Cumul";
import { Admin } from "./features/admin/Admin";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import history from "./history";
import PrivateRoute from "./components/PrivateRoute"; // Assurez-vous que le chemin est correct

import "./App.css";

export const App = () => {
  const utilisateur = useSelector((state) => state.connexion.utilisateur);
  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              return <Redirect exact to="/connexion" />;
            }}
          />
          <PrivateRoute path="/dashboard" component={() => (
            <>
              <Menu />
              <Dashboard />
            </>
          )} />
          <PrivateRoute path="/services" component={() => (
            <>
              <Menu />
              <Services />
            </>
          )} />
          <PrivateRoute path="/service/rapports" component={() => (
            <>
              <Menu />
              <Rapports />
            </>
          )} />
          <PrivateRoute path="/rapport/financier" component={() => (
            <>
              <Menu />
              <RapportFinancier />
            </>
          )} />
          <Route path="/connexion/:rest_id">
            <Connexion />
          </Route>
          <Route path="/connexion">
            <Connexion />
          </Route>
          <PrivateRoute path="/cumul" component={() => (
            <>
              <Menu />
              <Cumul />
            </>
          )} />
          <PrivateRoute exact path="/admin" component={() => (
            <>
              <Menu />
              {utilisateur.type === "superadmin" ? (
                <Admin />
              ) : (
                <Redirect exact to="/dashboard" />
              )}
            </>
          )} />
        </Switch>
      </Router>
    </div>
  );
};
