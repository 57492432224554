import React, { useState, useEffect } from "react";
import styles from "./Dashboard.module.css";

const LiveTable = ({ live, timeUntilRefresh }) => {
  const [isCompact, setIsCompact] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsCompact(window.innerWidth <= 500);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderTable = () => {
    if (!live || !live.titres || !live.designations) {
      return <p>Aucune donnée disponible pour le tableau.</p>;
    }

    const { titres, designations, couverts, ca, totals, dates, etatDuService } = live;

    const totalCouverts = [
      (couverts.enCours[0] || 0) + (couverts.enCours[1] || 0),
      (couverts.realise[0] || 0) + (couverts.realise[1] || 0),
    ];

    const totalCAEnCours = (ca.enCours[0] || 0) + (ca.enCours[1] || 0);
    const totalCARealise = (ca.realise[0] || 0) + (ca.realise[1] || 0);

    const totalGeneral = totals.TotalCA || 0;

    const dateServiceEnDirect = dates.serviceEnCours
      ? `Service du ${dates.serviceEnCours} en direct`
      : "Service en direct";

    const dateDernierServiceAJour = dates.serviceEnCours
      ? `Dernière mise à jour : ${dates.finServiceEnCours} à ${dates.heureFinServiceEnCours}`
      : "Pas de dernière mise à jour";

    const coalesceValue = (value) => value || 0;

    if (!isCompact) {
      return (
        <div className={styles.tableContainer}>
          <div className={styles.tableHeader}>
            <h2>
              {dateServiceEnDirect}
              <span className={styles.liveIndicator}></span>
            </h2>
            <p className={styles.chrono}>Rafraîchissement dans : {timeUntilRefresh}s</p>
          </div>
          <div className={styles.tableHeader}>
            <h6>{dateDernierServiceAJour}</h6>
          </div>
          <table className={styles.customTable}>
            <thead>
              <tr>
                <th>Désignation</th>
                {titres.map((titre, index) => (
                  <th key={index}>{titre}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {designations.map((designation, index) => (
                <tr key={index}>
                  <td>{designation}</td>
                  <td className={styles.splitCell}>
                    <div className={styles.topPart}>{coalesceValue(couverts.enCours[index])}</div>
                    <div className={styles.bottomPart}>{coalesceValue(couverts.realise[index])}</div>
                  </td>
                  <td>{coalesceValue(ca.enCours[index]).toFixed(2)}</td>
                  <td>{coalesceValue(ca.realise[index]).toFixed(2)}</td>
                </tr>
              ))}
              <tr className="subTotalRow">
                <td style={{ backgroundColor: "#d3d3d3" }}>
                  <strong>Sous-total</strong>
                </td>
                <td className={styles.splitCell} style={{ backgroundColor: "#d3d3d3" }}>
                  <div className={styles.topPart}>
                    <strong>{totalCouverts[0]}</strong>
                  </div>
                  <div className={styles.bottomPart}>
                    <strong>{totalCouverts[1]}</strong>
                  </div>
                </td>
                <td style={{ backgroundColor: "#d3d3d3" }}>
                  <strong>{totalCAEnCours.toFixed(2)}</strong>
                </td>
                <td style={{ backgroundColor: "#d3d3d3" }}>
                  <strong>{totalCARealise.toFixed(2)}</strong>
                </td>
              </tr>
              <tr className="totalRow">
                <td style={{ backgroundColor: "#d3d3d3" }}>
                  <strong>Total</strong>
                </td>
                <td style={{ backgroundColor: "#d3d3d3" }}>
                  <strong>{totals.CVTotal}</strong>
                </td>
                <td colSpan={2} style={{ backgroundColor: "#d3d3d3", textAlign: "center" }}>
                  <strong>{totalGeneral.toFixed(2)}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    } else {
      return (
        <div className={styles.tableContainer}>
          <div className={styles.tableHeader}>
            <h2>
              {dateServiceEnDirect}
              <span className={styles.liveIndicator}></span>
            </h2>
            <p className={styles.chrono}>Rafraîchissement dans : {timeUntilRefresh}s</p>
          </div>
          <table className={styles.customTable}>
            <thead>
              <tr>
                <th>Désignation</th>
                <th>CV en cours/réalisé</th>
                <th>CA en cours/réalisé</th>
              </tr>
            </thead>
            <tbody>
              {designations.map((designation, index) => (
                <tr key={index}>
                  <td>{designation}</td>
                  <td>
                    <div>{coalesceValue(couverts.enCours[index])}</div>
                    <div className={styles.bottomBorder}>{coalesceValue(couverts.realise[index])}</div>
                  </td>
                  <td>
                    <div>{coalesceValue(ca.enCours[index]).toFixed(2)}</div>
                    <div className={styles.bottomBorder}>{coalesceValue(ca.realise[index]).toFixed(2)}</div>
                  </td>
                </tr>
              ))}
              <tr className="subTotalRow">
                <td style={{ backgroundColor: "#d3d3d3" }}>
                  <strong>Sous-total</strong>
                </td>
                <td style={{ backgroundColor: "#d3d3d3" }}>
                  <div>
                    <strong>{totalCouverts[0]}</strong>
                  </div>
                  <div className={styles.bottomBorder}>
                    <strong>{totalCouverts[1]}</strong>
                  </div>
                </td>
                <td style={{ backgroundColor: "#d3d3d3" }}>
                  <div>
                    <strong>{totalCAEnCours.toFixed(2)}</strong>
                  </div>
                  <div className={styles.bottomBorder}>
                    <strong>{totalCARealise.toFixed(2)}</strong>
                  </div>
                </td>
              </tr>
              <tr className="totalRow">
                <td style={{ backgroundColor: "#d3d3d3" }}>
                  <strong>Total</strong>
                </td>
                <td style={{ backgroundColor: "#d3d3d3" }}>
                  <strong>{totals.CVTotal}</strong>
                </td>
                <td style={{ backgroundColor: "#d3d3d3", textAlign: "center" }} colSpan={2}>
                  <strong>{totalGeneral.toFixed(2)}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
  };

  return renderTable();
};

export default LiveTable;
