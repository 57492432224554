import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Connexion.module.css";
import {
  fetchConnexion,
  getConnexionFailure,
  connexionSelector,
} from "./connexionSlice";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Toast from "react-bootstrap/Toast";
import { fetchDeconnexion } from "../connexion/connexionSlice";
import { Redirect, useHistory, useParams, withRouter } from "react-router-dom";

export const Connexion = () => {
  const [rest_value, setRestValue] = useState("");
  const [values, setValues] = useState({});
  const history = useHistory();
  const { loading, hasErrors, message, errorMessage } =
    useSelector(connexionSelector);
  const utilisateur = useSelector((state) => state.connexion.utilisateur);
  const dispatch = useDispatch();
  let { rest_id } = useParams();

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (rest_id != 0 || rest_id != "") {
      setRestValue(rest_id);
    }
  }, [rest_id]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();

    if (!rest_value.trim()) {
      alert("Veuillez renseigner un restaurant.");
    } else if (!values.id.trim()) {
      alert("Veuillez renseigner un identifiant.");
    } else if (!values.mdp.trim()) {
      alert("Veuillez renseigner un mot de passe.");
    } else {
      dispatch(fetchConnexion(rest_value, values.id, values.mdp));
    }
  };

  /* if(utilisateur.connecte == true) {
    dispatch(fetchDeconnexion())
  }  */
  const errorFunction = () => {
    if (hasErrors) {
      if (errorMessage) {
        if (errorMessage.status == 401)
          return (
            <p className={styles.error_message}>
              {errorMessage.message.message}
            </p>
          );
        if (errorMessage.status == 403)
          return (
            <p className={styles.error_message}>
              {errorMessage.message.message}
            </p>
          );
      }
    }
  };

  const handleChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  var OuvrirRapport = (e) => {};

  return (
    <div className={styles.div_form_connexion}>
      <img
        className={styles.logo_connexion}
        src={process.env.PUBLIC_URL + "/POKKYWEB_noir_L.png"}
      />
      <div>{errorFunction()}</div>
      <form onSubmit={handleSubmit}>
        <label htmlFor="rest">Restaurant</label>
        <input
          name="rest"
          onChange={(e) => setRestValue(e.target.value)}
          value={rest_value || ""}
          required
        />
        <label htmlFor="id">Identifiant</label>
        <input
          name="id"
          onChange={handleChange}
          value={values.id || ""}
          required
        />
        <label htmlFor="mdp">Mot de passe</label>
        <div className={styles.passwordContainer}>
          <input
            name="mdp"
            onChange={handleChange}
            type={showPassword ? "text" : "password"}
            value={values.mdp || ""}
            className={styles.passwordInput}
            required
          />
          <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            onClick={togglePasswordVisibility}
            className={styles.eyeIcon} // Assurez-vous d'ajouter le style pour positionner correctement l'icône
          />
        </div>
        {/* <input
          name="mdp"
          onChange={handleChange}
          type="password"
          value={values.mdp || ""}
          required
        /> */}
        <Button
          type="submit"
          variant="outline-danger"
          className={styles.button_connexion}
          onClick={OuvrirRapport}
        >
          Se connecter
        </Button>
      </form>
      <footer>
        <p>Copyright Cocirel - Tous droits réservés</p>
      </footer>
    </div>
  );
};
