import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGroupe,
  adminSelector,
  patchGroupe,
  postGroupe,
  fetchRestaurant,
  patchRestaurant,
  fetchUtilisateur,
  patchUtilisateur,
  fetchAffectation,
  patchAffectation,
  deleteGroupe,
  postRestaurant,
  deleteRestaurant,
  deleteUtilisateur,
  postUtilisateur,
  deleteAffectation,
  updateLastUpdated,
  postAffectation,
} from "./adminSlice";
import { Table, Button, IconButton, Modal } from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import { Redirect, useHistory, useParams, withRouter } from "react-router-dom";
import { setRestaurant, connexionSelector } from "../connexion/connexionSlice";
import Message from "rsuite/Message";
import toaster from "rsuite/toaster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

import {
  format,
  differenceInHours,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
} from "date-fns";
import { resetErrorMessage } from "./adminSlice";
import styles from "./Admin.module.css";

const { HeaderCell, Cell, Column, ColumnGroup } = Table;
const userTypeOptions = [
  { label: "Superadmin", value: "superadmin" },
  { label: "Utilisateur", value: "utilisateur" },
];

/* Composant pour permettre la modification dans le tableau */
export const EditCell = ({ rowData, dataKey, onChange, type, ...props }) => {
  const editing = rowData.status === "EDIT";

  let id = 0;

  if (type == "groupe") id = rowData.grp_id;
  else if (type == "restaurant") id = rowData.rest_id;
  else if (type == "utilisateur") id = rowData.id;

  return (
    <Cell
      {...props}
      style={{ padding: "6px" }}
      className={editing ? "table-content-editing" : ""}
    >
      {editing ? (
        <input
          className="rs-input"
          value={rowData[dataKey]}
          onChange={(event) => {
            onChange && onChange(id, dataKey, event.target.value, type);
          }}
        />
      ) : (
        <span className="table-content-edit-span">{rowData[dataKey]}</span>
      )}
    </Cell>
  );
};
/* Modification sous forme de liste pour le tableau */
export const SelectCell = ({
  rowData,
  dataKey,
  onChange,
  type,
  data,
  data_groupe,
  isGroupList = false, // Nouvelle propriété pour identifier la liste des groupes
  ...props
}) => {
  const editing = rowData.status === "EDIT";
  // Gestion spécifique pour les groupes
  if (isGroupList && editing) {
    return (
      <Cell
        {...props}
        className={editing ? "table-content-editing" : ""}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <select
          className={`rs-input selectCell`}
          onChange={(event) => {
            onChange &&
              onChange(
                rowData.rest_id,
                dataKey,
                parseInt(event.target.value),
                type
              );
          }}
        >
          {Array.isArray(data_groupe) &&
            data_groupe.map((groupe) => (
              <option
                value={groupe.grp_id}
                selected={rowData.grp_id === groupe.grp_id}
              >
                {groupe.grp_nom}
              </option>
            ))}
        </select>
      </Cell>
    );
  }
  if (dataKey == "util_id") {
    return (
      <Cell
        {...props}
        className={editing ? "table-content-editing" : ""}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        {editing ? (
          <select
            className={`rs-input selectCell`}
            style={{
              padding: "8px 12px",
              height: "38px",
              boxSizing: "border-box",
            }}
            onChange={(event) => {
              onChange &&
                onChange(
                  rowData.rest_util_id,
                  dataKey,
                  event.target.value,
                  type
                );
            }}
          >
            {Array.isArray(data_groupe) &&
              data.map((utilisateur) =>
                rowData.util_id == utilisateur.id ? (
                  <option value={utilisateur.id} selected>
                    nom : {utilisateur.nom} | groupe : {utilisateur.grp_nom} |
                    id : {utilisateur.id}
                  </option>
                ) : (
                  <option value={utilisateur.id}>
                    nom : {utilisateur.nom} | groupe : {utilisateur.grp_nom} |
                    id : {utilisateur.id}
                  </option>
                )
              )}
          </select>
        ) : (
          <span className="table-content-edit-span">{rowData[dataKey]}</span>
        )}
      </Cell>
    );
  } else if (dataKey === "type" && type === "utilisateur") {
    const id = rowData.id;
    return (
      <Cell
        {...props}
        className={editing ? "table-content-editing" : ""}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        {editing ? (
          <select
            className={`rs-input selectCell`}
            onChange={(event) => {
              onChange && onChange(id, dataKey, event.target.value, type);
            }}
            value={rowData[dataKey]}
          >
            <option value="">
              {rowData[dataKey] ? "" : "Sélectionner un type"}
            </option>
            {userTypeOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        ) : (
          <span className="table-content-edit-span">{rowData[dataKey]}</span>
        )}
      </Cell>
    );
  } else if (editing && dataKey === "rest_id") {
    return (
      <Cell
        {...props}
        className={editing ? "table-content-editing" : ""}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <select
          className={`rs-input selectCell`}
          onChange={(event) => {
            onChange &&
              onChange(
                rowData.rest_util_id, // Assurez-vous que c'est l'identifiant correct à utiliser ici
                dataKey,
                event.target.value,
                type
              );
          }}
        >
          {Array.isArray(data) &&
            data.map((restaurant) => (
              <option
                value={restaurant.rest_id}
                selected={rowData.rest_id === restaurant.rest_id}
              >
                {`nom : ${restaurant.rest_nom} | groupe : ${restaurant.grp_nom} | id : ${restaurant.rest_id}`}
              </option>
            ))}
        </select>
      </Cell>
    );
  } else {
    return (
      <Cell
        {...props}
        className={editing ? "table-content-editing" : ""}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        {editing ? (
          <select
            className={`rs-input selectCell`}
            onChange={(event) => {
              onChange &&
                onChange(
                  rowData.rest_util_id,
                  dataKey,
                  event.target.value,
                  type
                );
            }}
          >
            {Array.isArray(data_groupe) &&
              data.map((restaurant) =>
                rowData.rest_id == restaurant.rest_id ? (
                  <option value={restaurant.rest_id} selected>
                    nom : {restaurant.rest_nom} | groupe : {restaurant.grp_nom}{" "}
                    | id : {restaurant.rest_id}
                  </option>
                ) : (
                  <option value={restaurant.rest_id}>
                    nom : {restaurant.rest_nom} | groupe : {restaurant.grp_nom}{" "}
                    | id : {restaurant.rest_id}
                  </option>
                )
              )}
          </select>
        ) : (
          <span className="table-content-edit-span">{rowData[dataKey]}</span>
        )}
      </Cell>
    );
  }
};

/* Composant qui gère l'action de modification */
const ActionCell = ({
  rowData,
  dataKey,
  onClick,
  onCancelEdit, // Ajout de la nouvelle fonction
  type,
  confirmDelete,
  ...props
}) => {
  let id = 0;

  if (type == "groupe") id = rowData.grp_id;
  else if (type == "restaurant") id = rowData.rest_id;
  else if (type == "utilisateur") id = rowData.id;
  else if (type == "affectation") id = rowData.rest_util_id;

  return (
    <Cell {...props} style={{ padding: "6px" }}>
      <Button appearance="link" onClick={() => onClick(id, type)}>
        {rowData.status === "EDIT" ? "Enregistrer" : "Modifier"}
      </Button>
      {rowData.status === "EDIT" ? (
        <Button
          appearance="link"
          onClick={() => onCancelEdit(id, type)}
          style={{ marginLeft: "10px" }}
        >
          Annuler
        </Button>
      ) : (
        <Button appearance="link" onClick={() => confirmDelete(id, type)}>
          Supprimer
        </Button>
      )}
    </Cell>
  );
};

export const Admin = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const clearError = () => {
    dispatch(resetErrorMessage());
  };

  const handleClose = () => {
    setOpen(false);
    clearError(); // Réinitialise le message d'erreur lors de la fermeture du modal
  };

  const {
    loading,
    loaded,
    hasErrors,
    groupe,
    restaurant,
    utilisateur,
    affectation,
    loaded_groupe,
    loaded_restaurant,
    loaded_utilisateur,
    loaded_affectation,
    errorMessage,
  } = useSelector(adminSelector);
  const [ajout, setAjout] = useState("");

  /* Gestion de l'ajout */

  /* Ajout groupe */
  const [grp_nom_form, setGrpNomForm] = useState("");
  /* Ajout restaurant */
  const [rest_id_form, setRestIdForm] = useState("");
  const [rest_nom_form, setRestNomForm] = useState("");
  const [grp_id_form, setGrpIdForm] = useState(1);
  /* Ajout utilisateur */
  const [util_id_form, setUtilIdForm] = useState("");
  const [util_mdp_form, setUtilMdpForm] = useState("");
  const [util_type_form, setUtilTypeForm] = useState("");
  /* Ajout affectation */
  const [affect_util_form, setAffectUtilForm] = useState("");
  const [affect_rest_form, setAffectRestForm] = useState("");

  /* Gestion des données en local */
  const [data_groupe, setDataGroupe] = useState([]);
  const [data_restaurant, setDataRestaurant] = useState([]);
  const [data_utilisateur, setDataUtilisateur] = useState([]);
  const [data_affectation, setDataAffectation] = useState([]);
  const [backupDataGroupe, setBackupDataGroupe] = useState([]);
  const [backupDataRestaurant, setBackupDataRestaurant] = useState([]);
  const [backupDataUtilisateur, setBackupDataUtilisateur] = useState([]);
  const [backupDataAffiliation, setBackupDataAffiliation] = useState([]);
  /* Modal */
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  /* Gestion des événements affichage dynamique */
  useEffect(() => {
    dispatch(fetchGroupe()).then(setDataGroupe(groupe));
  }, [dispatch]);

  useEffect(() => {
    setDataGroupe(groupe);
  }, [loaded_groupe]);

  useEffect(() => {
    dispatch(fetchRestaurant()).then(setDataRestaurant(restaurant));
  }, [dispatch]);

  useEffect(() => {
    setDataRestaurant(restaurant);
  }, [loaded_restaurant]);

  useEffect(() => {
    dispatch(fetchUtilisateur()).then(setDataUtilisateur(utilisateur));
  }, [dispatch]);

  useEffect(() => {
    setDataUtilisateur(utilisateur);
  }, [loaded_utilisateur]);

  useEffect(() => {
    dispatch(fetchAffectation()).then(setDataAffectation(affectation));
  }, [dispatch]);

  useEffect(() => {
    setDataAffectation(affectation);
  }, [loaded_affectation]);

  useEffect(() => {
    if (errorMessage) {
      toaster.push(
        <Message showIcon type="error">
          {errorMessage}
        </Message>,
        { placement: "topEnd" }
      );
    }
  }, [errorMessage]);

  // Gestion de suppression utilisateur
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  // Gestion de suppression groupe
  const [deleteGroupModalOpen, setDeleteGroupModalOpen] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState(null);

  const [deleteAffectationModalOpen, setDeleteAffectationModalOpen] =
    useState(false);
  const [affectationToDelete, setAffectationToDelete] = useState(null);

  const [deleteRestaurantModalOpen, setDeleteRestaurantModalOpen] =
    useState(false);
  const [restaurantToDelete, setRestaurantToDelete] = useState(null);

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const { utilisateur: utilisateurConnecte } = useSelector(connexionSelector);
  const currentUserId = utilisateurConnecte ? utilisateurConnecte.id : null;

  const isCurrentUser = (userId) => {
    return userId === currentUserId;
  };

  const showPopupMessage = () => {
    toaster.push(
      <Message showIcon type="warning">
        Vous ne pouvez pas supprimer votre propre compte.
      </Message>,
      { placement: "topEnd" }
    );
  };
  const showGroupUsedWarning = () => {
    toaster.push(
      <Message showIcon type="warning">
        Ce groupe est actuellement utilisé par votre profil et ne peut pas être
        supprimé.
      </Message>,
      { placement: "topEnd" }
    );
  };
  const showAffectUsedWarning = () => {
    toaster.push(
      <Message showIcon type="warning">
        Cette affectation est actuellement utilisé par votre profil et ne peut
        pas être supprimé.
      </Message>,
      { placement: "topEnd" }
    );
  };
  const showRestaurantUsedWarning = () => {
    toaster.push(
      <Message showIcon type="warning">
        Ce Restaurant est actuellement utilisé par votre profil et ne peut pas
        être supprimé.
      </Message>,
      { placement: "topEnd" }
    );
  };

  const confirmDeleteUser = (userId) => {
    setUserToDelete(userId);
    setDeleteUserModalOpen(true);
  };

  const [lastUpdated, setLastUpdated] = useState(new Date());

  useEffect(() => {
    // Supposons que groupe, restaurant, utilisateur, affectation sont des états provenant de Redux
    setLastUpdated(new Date());
  }, [groupe, restaurant, utilisateur, affectation]);

  // Exécute la logique de suppression après confirmation
  const deleteUser = async () => {
    if (userToDelete) {
      // Vérifiez d'abord s'il y a des affectations à supprimer
      const userAffectations = data_affectation.filter(
        (affectation) => affectation.util_id === userToDelete
      );

      for (const affectation of userAffectations) {
        await dispatch(deleteAffectation(affectation.rest_util_id));
      }

      // Ensuite, supprimez l'utilisateur
      await dispatch(deleteUtilisateur(userToDelete));

      // Mettre à jour l'état local après la suppression réussie
      setDataUtilisateur(
        data_utilisateur.filter(
          (utilisateur) => utilisateur.id !== userToDelete
        )
      );

      // Fermez la modal et réinitialisez l'état
      setDeleteUserModalOpen(false);
      setUserToDelete(null);
    }
  };

  const handleCancelEdit = (
    id,
    type,
    {
      data_groupe,
      setDataGroupe,
      data_restaurant,
      setDataRestaurant,
      data_utilisateur,
      setDataUtilisateur,
      data_affectation,
      setDataAffectation,
    }
  ) => {
    let nextData;

    switch (type) {
      case "groupe":
        nextData = backupDataGroupe.map((item) => {
          if (item.grp_id === id) {
            return { ...item, status: null };
          }
          return item;
        });
        setDataGroupe(nextData);
        break;

      case "restaurant":
        nextData = backupDataRestaurant.map((item) => {
          if (item.rest_id === id) {
            return { ...item, status: null };
          }
          return item;
        });
        setDataRestaurant(nextData);
        break;

      case "utilisateur":
        nextData = backupDataUtilisateur.map((item) => {
          if (item.id === id) {
            return { ...item, status: null };
          }
          return item;
        });
        setDataUtilisateur(nextData);
        break;

      case "affectation":
        nextData = backupDataAffiliation.map((item) => {
          if (item.rest_util_id === id) {
            return { ...item, status: null };
          }
          return item;
        });
        setDataAffectation(nextData);
        break;

      default:
        break;
    }
  };

  const confirmDeleteGroup = (grp_id) => {
    setGroupToDelete(grp_id);
    setDeleteGroupModalOpen(true);
  };

  const isGroupOfCurrentUser = (grpId) => {
    // Obtenez l'identifiant du groupe de l'utilisateur connecté
    const currentUserGroupId = utilisateurConnecte
      ? utilisateurConnecte.grp_id
      : null;

    // Comparez-le avec l'identifiant du groupe passé en paramètre
    return grpId === currentUserGroupId;
  };

  // const isGroupOfCurrentUser = (grpId) => {
  //   const currentUserGroupId = utilisateurConnecte
  //     ? utilisateurConnecte.grp_id
  //     : null;
  //   return currentUserGroupId === grpId;
  // };

  const isAffectationOfCurrentUser = (affectationUserId) => {
    return affectationUserId === currentUserId;
  };

  const isRestaurantLinkedToCurrentUser = (restaurantId) => {
    return data_affectation?.some(
      (affectation) =>
        affectation.rest_id === restaurantId &&
        affectation.util_id === currentUserId
    );
  };

  // Fonction pour effectuer la suppression en cascade
  const deleteGroup = async () => {
    if (groupToDelete) {
      const groupName = data_groupe.find(
        (g) => g.grp_id === groupToDelete
      ).grp_nom;
      // Trouvez tous les utilisateurs liés à ce groupe
      const usersOfGroup = data_utilisateur.filter(
        (utilisateur) => utilisateur.grp_nom === groupName
      );

      // Supprimez les utilisateurs et leurs affectations
      for (const user of usersOfGroup) {
        const userAffectations = data_affectation.filter(
          (affectation) => affectation.util_id === user.id
        );

        for (const affectation of userAffectations) {
          await dispatch(deleteAffectation(affectation.rest_util_id));
        }

        await dispatch(deleteUtilisateur(user.id));
      }

      // Trouvez tous les restaurants liés à ce groupe
      const restaurantsOfGroup = data_restaurant.filter(
        (restaurant) => restaurant.grp_id === groupToDelete
      );

      // Supprimez les restaurants
      for (const restaurant of restaurantsOfGroup) {
        await dispatch(deleteRestaurant(restaurant.rest_id));
      }

      // Supprimez le groupe
      await dispatch(deleteGroupe(groupToDelete));

      // Mettre à jour l'état local
      setDataGroupe(
        data_groupe.filter((groupe) => groupe.grp_id !== groupToDelete)
      );
      setDataRestaurant(
        data_restaurant.filter(
          (restaurant) => restaurant.grp_id !== groupToDelete
        )
      );
      setDataUtilisateur(
        data_utilisateur.filter(
          (utilisateur) => utilisateur.grp_nom !== groupName
        )
      );

      // Fermez la modal et réinitialisez l'état
      setDeleteGroupModalOpen(false);
      setGroupToDelete(null);
    }
  };

  const confirmDeleteAffectation = (id) => {
    setAffectationToDelete(id);
    setDeleteAffectationModalOpen(true);
  };

  const confirmDeleteRestaurant = (id) => {
    setRestaurantToDelete(id);
    setDeleteRestaurantModalOpen(true);
  };

  const generateRandomNumber = () => {
    const length = Math.floor(Math.random() * (10 - 6 + 1)) + 6; // Génère un nombre entre 6 et 10
    let randomNumber = "";
    for (let i = 0; i < length; i++) {
      randomNumber += Math.floor(Math.random() * 10);
    }

    checkIfNumberExists(randomNumber, true);
  };

  const checkIfNumberExists = (number, isAutomatic = false) => {
    const restaurantExists = data_restaurant.some(
      (restaurant) => restaurant.rest_id === number
    );

    if (restaurantExists) {
      if (isAutomatic) {
        // Si la vérification est automatique et que l'identifiant existe déjà, générez-en un nouveau
        generateRandomNumber();
      } else {
        // Si la vérification est manuelle, affichez un message d'erreur
        toaster.push(
          <Message showIcon type="error">
            L'identifiant "{number}" existe déjà. Veuillez en choisir un autre.
          </Message>,
          { placement: "topEnd" }
        );
      }
    } else {
      setRestIdForm(number);
    }
  };

  /* Modification */
  const handleChange = (id, key, value, type) => {
    let nextData;
    if (type === "groupe") {
      const nextData = JSON.parse(JSON.stringify([...data_groupe]));
      const updatedGroupe = nextData.find((item) => item.grp_id === id);
      const oldGroupName = updatedGroupe.grp_nom;

      updatedGroupe[key] = value;

      if (key === "grp_nom") {
        // Mise à jour des utilisateurs appartenant à l'ancien groupe
        const updatedUsers = data_utilisateur.map((user) => {
          if (user.grp_nom === oldGroupName) {
            return { ...user, grp_nom: value };
          }
          return user;
        });
        setDataUtilisateur(updatedUsers);
      }

      setDataGroupe(nextData);
    } else if (type == "restaurant") {
      nextData = data_restaurant.map((item) => {
        if (item.rest_id === id) {
          if (key === "grp_id") {
            const grpNom = data_groupe.find(
              (groupe) => groupe.grp_id === parseInt(value)
            )?.grp_nom;
            item = { ...item, grp_id: value, grp_nom: grpNom };

            // Mettre à jour les données de groupe dans les utilisateurs affectés à ce restaurant
            const updatedUsers = data_utilisateur.map((user) => {
              const isUserAffected = data_affectation.some(
                (affect) =>
                  affect.util_id === user.id && affect.rest_id === item.rest_id
              );
              if (isUserAffected) {
                return { ...user, grp_id: parseInt(value), grp_nom: grpNom };
              }
              return user;
            });
            setDataUtilisateur(updatedUsers);

            // Mettre à jour le groupe dans les affectations
            const updatedAffectations = data_affectation.map((affect) => {
              if (affect.rest_id === item.rest_id) {
                return { ...affect, rest_nom: item.rest_nom }; // Assurez-vous de mettre à jour rest_nom si nécessaire
              }
              return affect;
            });
            setDataAffectation(updatedAffectations);
          } else {
            item = { ...item, [key]: value };
          }
        }
        return item;
      });
      setDataRestaurant(nextData);
    } else if (type == "utilisateur") {
      const nextData = JSON.parse(
        JSON.stringify(Object.assign([], data_utilisateur))
      );
      nextData.find((item) => item.id === id)[key] = value;
      dispatch(updateLastUpdated(new Date()));
      setDataUtilisateur(nextData);
    } else if (type === "affectation") {
      const nextAffectationData = JSON.parse(
        JSON.stringify(Object.assign([], data_affectation))
      );
      const activeAffectation = nextAffectationData.find(
        (item) => item.rest_util_id === id
      );

      if (activeAffectation) {
        activeAffectation[key] = value;

        if (key === "rest_id") {
          const restaurant = data_restaurant.find((r) => r.rest_id === value);
          if (restaurant) {
            activeAffectation.rest_nom = restaurant.rest_nom;

            // Mettre à jour le groupe de l'utilisateur
            const nextUserData = data_utilisateur.map((user) => {
              if (user.id === activeAffectation.util_id) {
                return {
                  ...user,
                  grp_nom: restaurant.grp_nom,
                  grp_id: restaurant.grp_id,
                };
              }
              return user;
            });

            setDataUtilisateur(nextUserData);
          }
        }
      }

      dispatch(updateLastUpdated(new Date()));
      setDataAffectation(nextAffectationData);
    }
  };

  /* Evenement pour la modification / Enregistrement des modifications */
  const handleEditState = (id, type) => {
    if (type === "groupe") {
      const nextData = JSON.parse(JSON.stringify([...data_groupe]));
      setBackupDataGroupe(nextData);
      const activeItem = nextData.find((item) => item.grp_id === id);

      // Vérifier si un autre groupe avec le même nom existe déjà.
      const isDuplicateName = data_groupe.some(
        (item) => item.grp_nom === activeItem.grp_nom && item.grp_id !== id
      );

      if (isDuplicateName) {
        // Afficher le message d'erreur
        toaster.push(
          <Message showIcon type="error">
            Un groupe avec ce nom existe déjà!
          </Message>,
          { placement: "topEnd" }
        );
        // Réinitialiser la liste des groupes
        setDataGroupe(groupe);
      } else {
        if (activeItem.status === "EDIT") {
          dispatch(patchGroupe(activeItem));

          // Mise à jour des utilisateurs appartenant à ce groupe
          const oldGroupName = data_groupe.find((g) => g.grp_id === id).grp_nom;
          const updatedUsers = data_utilisateur.map((user) => {
            if (user.grp_nom === oldGroupName) {
              return { ...user, grp_nom: activeItem.grp_nom };
            }
            return user;
          });
          setDataUtilisateur(updatedUsers);
        }

        activeItem.status = activeItem.status ? null : "EDIT";
        setDataGroupe(nextData);
      }
    } else if (type == "restaurant") {
      const nextData = JSON.parse(
        JSON.stringify(Object.assign([], data_restaurant))
      );
      setBackupDataRestaurant(nextData);
      const activeItem = nextData.find((item) => item.rest_id === id);
      if (activeItem.status === "EDIT") dispatch(patchRestaurant(activeItem));

      activeItem.status = activeItem.status ? null : "EDIT";
      setDataRestaurant(nextData);
    } else if (type == "utilisateur") {
      const nextData = JSON.parse(
        JSON.stringify(Object.assign([], data_utilisateur))
      );
      setBackupDataUtilisateur(nextData);
      const activeItem = nextData.find((item) => item.id === id);

      if (activeItem.status === "EDIT") dispatch(patchUtilisateur(activeItem));

      activeItem.status = activeItem.status ? null : "EDIT";
      setDataUtilisateur(nextData);
    } else if (type == "affectation") {
      const nextData = JSON.parse(
        JSON.stringify(Object.assign([], data_affectation))
      );
      const activeItem = nextData.find((item) => item.rest_util_id === id);

      // Vérification pour empêcher l'affectation d'un utilisateur déjà affecté au même restaurant
      if (activeItem.status === "EDIT") {
        const isAlreadyAssigned = nextData.some(
          (item) =>
            item.util_id === activeItem.util_id &&
            item.rest_id === activeItem.rest_id &&
            item.rest_util_id !== activeItem.rest_util_id
        );

        if (isAlreadyAssigned) {
          // Afficher un popup d'avertissement et ne pas sauvegarder les modifications
          toaster.push(
            <Message showIcon type="error">
              Cet utilisateur est déjà affecté à ce restaurant.
            </Message>,
            { placement: "topEnd" }
          );
          return; // Quitte la fonction pour empêcher la sauvegarde
        } else {
          // Procéder à la sauvegarde des modifications
          dispatch(patchAffectation(activeItem));
        }
      }
      setBackupDataAffiliation(nextData);
      if (activeItem.status === "EDIT") dispatch(patchAffectation(activeItem));
      activeItem.status = activeItem.status ? null : "EDIT";
      setDataAffectation(nextData);
    }
  };

  /* (Modal == fenêtre) d'ajout */
  const handleModal = (type) => {
    setAjout(type);
    handleOpen();
  };

  /* Titre du modal */
  const titreFormulaireAjout = () => {
    if (ajout === "Groupe") return <span>Ajout d'un nouveau groupe</span>;
    else if (ajout === "Restaurant")
      return <span>Ajout d'un nouveau restaurant</span>;
    else if (ajout === "Utilisateur")
      return <span>Ajout d'un nouveau utilisateur</span>;
    else if (ajout === "Affectation")
      return (
        <span>
          Affecter un utilisateur à un restaurant
          <br></br>
          (doit appartenir au même groupe)
        </span>
      );
  };

  const handleIdChange = (value) => {
    setRestIdForm(value);
    if (value && value.length > 0) {
      checkIfNumberExists(value);
    }
  };

  /* Formulaire du modal */
  const formulaireAjout = (ajout) => {
    if (ajout === "Groupe")
      return (
        <div>
          Nom du groupe :{" "}
          <input
            name="grp_nom_form"
            className="rs-input"
            onChange={(e) => setGrpNomForm(e.target.value)}
            value={grp_nom_form || ""}
            required
          />
        </div>
      );
    else if (ajout === "Restaurant") {
      return (
        <div>
          <div>Identifiant : </div>
          <div>
            <input
              name="rest_id_form"
              onChange={(e) => handleIdChange(e.target.value)}
              value={rest_id_form || ""}
              className="rs-input"
              required
            />
            {/* <Button onClick={generateRandomNumber}>Générer ID</Button> */}
          </div>
          <div>Nom : </div>
          <div>
            <input
              name="rest_nom_form"
              onChange={(e) => setRestNomForm(e.target.value)}
              value={rest_nom_form || ""}
              className="rs-input"
              required
            />
          </div>
          <div>Groupe : </div>
          <div>
            <select
              className="rs-input"
              value={grp_id_form}
              onChange={(e) => setGrpIdForm(e.target.value)}
            >
              <option value="">Sélectionner un groupe</option>{" "}
              {/* Option initiale ajoutée */}
              {Array.isArray(data_groupe) &&
                data_groupe.map((groupe) => (
                  <option key={groupe.grp_id} value={groupe.grp_id}>
                    id : {groupe.grp_id} nom : {groupe.grp_nom}
                  </option>
                ))}
            </select>
          </div>
        </div>
      );
    } else if (ajout == "Utilisateur") {
      return (
        <div>
          <div>Identifiant de connexion : </div>
          <div>
            <input
              name="util_id_form"
              onChange={(e) => setUtilIdForm(e.target.value)}
              value={util_id_form || ""}
              className="rs-input"
              autoComplete="off"
              required
            />
          </div>
          <div>Mot de passe : </div>
          <div className={styles.passwordContainer}>
            <input
              name="util_mdp_form"
              type={showPassword ? "text" : "password"}
              onChange={(e) => setUtilMdpForm(e.target.value)}
              value={util_mdp_form || ""}
              autoComplete="new-password"
              required
              className="rs-input"
            />
            <FontAwesomeIcon
              icon={showPassword ? faEyeSlash : faEye}
              onClick={togglePasswordVisibility}
              className={styles.eyeIcon}
            />
          </div>
          <div>Type : </div>
          <div>
            <select
              className="rs-input"
              value={util_type_form}
              onChange={(e) => setUtilTypeForm(e.target.value)}
            >
              <option value="">Sélectionner un type</option>{" "}
              {/* Option initiale pour le type */}
              {Array.isArray(data_groupe) &&
                userTypeOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </select>
          </div>
          <div>Groupe : </div>
          <div>
            <select
              style={{
                padding: "8px 12px",
                height: "38px",
                boxSizing: "border-box",
              }}
              className="rs-input"
              value={grp_id_form}
              onChange={(e) => setGrpIdForm(e.target.value)}
            >
              <option value="">Sélectionner un groupe</option>{" "}
              {/* Option initiale pour le groupe */}
              {Array.isArray(data_groupe) &&
                data_groupe.map((groupe) => (
                  <option key={groupe.grp_id} value={groupe.grp_id}>
                    id : {groupe.grp_id} nom : {groupe.grp_nom}
                  </option>
                ))}
            </select>
          </div>
        </div>
      );
    } else if (ajout == "Affectation") {
      return (
        <div>
          <div>Utilisateur : </div>
          <div>
            <select
              className="rs-input"
              value={affect_util_form}
              onChange={(e) => setAffectUtilForm(e.target.value)}
            >
              <option value="">Sélectionner un utilisateur</option>
              {data_utilisateur.map((util) => (
                <option key={util.id} value={util.id}>
                  {util.nom} du groupe {util.grp_nom}
                </option>
              ))}
            </select>
          </div>

          <div>Restaurant : </div>
          <div>
            <select
              className="rs-input"
              style={{
                padding: "8px 12px",
                height: "38px",
                boxSizing: "border-box",
              }}
              value={affect_rest_form}
              onChange={(e) => setAffectRestForm(e.target.value)}
            >
              <option value="">Sélectionner un restaurant</option>
              {data_restaurant.map((rest) => (
                <option key={rest.rest_id} value={rest.rest_id}>
                  {rest.rest_id} du restaurant {rest.rest_nom} du groupe{" "}
                  {rest.grp_nom}
                </option>
              ))}
            </select>
          </div>
        </div>
      );
    }
  };

  /* Modal : Gestion de l'ajout  */
  const gestionAjout = () => {
    if (ajout === "Groupe") {
      if (grp_nom_form.trim() !== "") {
        dispatch(postGroupe(JSON.stringify({ grp_nom: grp_nom_form })))
          .then(() => {
            // Rafraîchir la liste des groupes, des restaurants et des utilisateurs
            dispatch(fetchGroupe());
            dispatch(fetchRestaurant());
            dispatch(fetchUtilisateur());
            dispatch(fetchAffectation());
          })
          .then(() => {
            // Mettre à jour les données locales avec les nouvelles listes
            setDataGroupe(groupe);
            setDataRestaurant(restaurant);
            setDataUtilisateur(utilisateur);
            setDataAffectation(affectation);
            handleClose();
          });

        setGrpNomForm("");
      } else {
        const message = (
          <Message showIcon type="warning">
            Veuillez renseigner un nom pour le groupe
          </Message>
        );

        toaster.push(message, "topEnd");
      }
    } else if (ajout === "Restaurant") {
      if (
        rest_id_form.trim() !== "" &&
        rest_nom_form.trim() !== "" &&
        grp_id_form !== ""
      ) {
        dispatch(
          postRestaurant(
            JSON.stringify({
              rest_id: rest_id_form,
              rest_nom: rest_nom_form,
              grp_id: grp_id_form,
            })
          )
        )
          .then(() => {
            // Rafraîchir la liste des groupes, des restaurants et des utilisateurs
            dispatch(fetchGroupe());
            dispatch(fetchRestaurant());
            dispatch(fetchUtilisateur());
            dispatch(fetchAffectation());
          })
          .then(() => {
            // Mettre à jour les données locales avec les nouvelles listes
            setDataGroupe(groupe);
            setDataRestaurant(restaurant);
            setDataUtilisateur(utilisateur);
            setDataAffectation(affectation); // Mettre à jour les données de groupe
            handleClose();
            dispatch(fetchRestaurant());
          });

        setRestIdForm("");
        setRestNomForm("");
        setGrpIdForm("");
      } else {
        const message = (
          <Message showIcon type="warning">
            Veuillez renseigner tous les éléments
          </Message>
        );

        toaster.push(message, "topEnd");
      }
    } else if (ajout === "Utilisateur") {
      if (
        util_id_form.trim() !== "" &&
        util_mdp_form.trim() !== "" &&
        util_type_form.trim() !== "" &&
        grp_id_form !== ""
      ) {
        dispatch(
          postUtilisateur(
            JSON.stringify({
              nom: util_id_form,
              mdp: util_mdp_form,
              type: util_type_form,
              grp_id: grp_id_form,
            })
          )
        )
          .then(() => {
            // Rafraîchir la liste des groupes, des restaurants et des utilisateurs
            dispatch(fetchGroupe());
            dispatch(fetchRestaurant());
            dispatch(fetchUtilisateur());
            dispatch(fetchAffectation());
          })
          .then(() => {
            // Mettre à jour les données locales avec les nouvelles listes
            setDataGroupe(groupe);
            setDataRestaurant(restaurant);
            setDataUtilisateur(utilisateur);
            setDataAffectation(affectation);
            handleClose();
            dispatch(fetchUtilisateur());
          });

        setUtilIdForm("");
        setUtilMdpForm("");
        setUtilTypeForm("");
        setGrpIdForm("");
      } else {
        const message = (
          <Message showIcon type="warning">
            Veuillez renseigner tous les éléments
          </Message>
        );

        toaster.push(message, "topEnd");
      }
    } else if (ajout === "Affectation") {
      if (affect_util_form !== "" && affect_rest_form !== "") {
        // Vérifiez si cette combinaison utilisateur-restaurant existe déjà
        const alreadyExists = data_affectation.some(
          (affectation) =>
            affectation.util_id === affect_util_form &&
            affectation.rest_id === affect_rest_form
        );

        if (!alreadyExists) {
          dispatch(
            postAffectation(
              JSON.stringify({
                util_id: affect_util_form,
                rest_id: affect_rest_form,
              })
            )
          )
            .then(() => {
              // Rafraîchir la liste des groupes, des restaurants et des utilisateurs
              dispatch(fetchGroupe());
              dispatch(fetchRestaurant());
              dispatch(fetchUtilisateur());
              dispatch(fetchAffectation());
            })
            .then(() => {
              // Mettre à jour les données locales avec les nouvelles listes
              setDataGroupe(groupe);
              setDataRestaurant(restaurant);
              setDataUtilisateur(utilisateur);
              setDataAffectation(affectation);
              handleClose();
              dispatch(fetchAffectation());
            });

          setAffectRestForm("");
          setAffectUtilForm("");
        } else {
          toaster.push(
            <Message showIcon type="warning">
              Cette affectation existe déjà.
            </Message>,
            { placement: "topEnd" }
          );
        }
      } else {
        const message = (
          <Message showIcon type="warning">
            Veuillez renseigner tous les éléments
          </Message>
        );

        toaster.push(message, "topEnd");
      }
    }
  };

  return (
    <div className={styles.grille_admin}>
      <div>
        <div className={styles.div_tableau_titre}>
          <h3 className={styles.tableau_titre}>Groupe</h3>
          <div className={styles.div_icone_ajouter}>
            <IconButton
              className={styles.icone_ajouter}
              icon={<PlusIcon />}
              onClick={() => handleModal("Groupe")}
            >
              Ajouter
            </IconButton>
          </div>
        </div>
        <Table virtualized data={data_groupe}>
          <Column>
            <HeaderCell>Identifiant</HeaderCell>
            <Cell dataKey="grp_id" />
          </Column>

          <Column flexGrow={1} align="left">
            <HeaderCell>Nom</HeaderCell>
            <EditCell dataKey="grp_nom" type="groupe" onChange={handleChange} />
          </Column>

          <Column>
            <HeaderCell>Action</HeaderCell>
            <ActionCell
              dataKey="grp_id"
              type="groupe"
              onClick={handleEditState}
            />
          </Column>
          <Column>
            <HeaderCell>Supprimer</HeaderCell>
            <Cell style={{ padding: "6px" }}>
              {(rest) => {
                // Si la ligne est en mode édition, affichez le bouton "Annuler"
                if (rest.status === "EDIT") {
                  return (
                    <Button
                      appearance="link"
                      onClick={() =>
                        handleCancelEdit(rest.grp_id, "groupe", {
                          data_groupe,
                          setDataGroupe,
                          data_restaurant,
                          setDataRestaurant,
                          data_utilisateur,
                          setDataUtilisateur,
                          data_affectation,
                          setDataAffectation,
                        })
                      }
                    >
                      Annuler
                    </Button>
                  );
                }

                // Sinon, affichez le bouton "Supprimer" habituel
                if (isGroupOfCurrentUser(rest.grp_id)) {
                  return (
                    <Button
                      appearance="link"
                      onClick={showGroupUsedWarning}
                      style={{ textDecoration: "line-through", opacity: 0.6 }}
                    >
                      Supprimer
                    </Button>
                  );
                } else {
                  return (
                    <Button
                      appearance="link"
                      onClick={() => confirmDeleteGroup(rest.grp_id)}
                    >
                      Supprimer
                    </Button>
                  );
                }
              }}
            </Cell>
          </Column>
        </Table>
      </div>
      <div>
        <div className={styles.div_tableau_titre}>
          <h3 className={styles.tableau_titre}>Restaurant</h3>
          <div className={styles.div_icone_ajouter}>
            <IconButton
              className={styles.icone_ajouter}
              icon={<PlusIcon />}
              onClick={() => handleModal("Restaurant")}
            >
              Ajouter
            </IconButton>
          </div>
        </div>
        <Table virtualized data={data_restaurant}>
          <Column flexGrow={2}>
            <HeaderCell>Identifiant</HeaderCell>
            <Cell dataKey="rest_id" />
          </Column>

          <Column flexGrow={3}>
            <HeaderCell>Nom</HeaderCell>
            <EditCell
              dataKey="rest_nom"
              type="restaurant"
              onChange={handleChange}
            />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Groupe</HeaderCell>
            <SelectCell
              dataKey="grp_id"
              type="restaurant"
              data_groupe={data_groupe} // Passer les données des groupes
              onChange={handleChange}
              isGroupList={true} // Indiquer que c'est une liste de groupes
            />
          </Column>
          <Column>
            <HeaderCell>Action</HeaderCell>
            <ActionCell
              dataKey="rest_id"
              type="restaurant"
              onClick={handleEditState}
            />
          </Column>
          <Column>
            <HeaderCell>Supprimer</HeaderCell>
            <Cell style={{ padding: "6px" }}>
              {(rest) => {
                // Si la ligne est en mode édition, affichez le bouton "Annuler"
                if (rest.status === "EDIT") {
                  return (
                    <Button
                      appearance="link"
                      onClick={() =>
                        handleCancelEdit(rest.rest_id, "restaurant", {
                          data_groupe,
                          setDataGroupe,
                          data_restaurant,
                          setDataRestaurant,
                          data_utilisateur,
                          setDataUtilisateur,
                          data_affectation,
                          setDataAffectation,
                        })
                      }
                    >
                      Annuler
                    </Button>
                  );
                }
                if (isRestaurantLinkedToCurrentUser(rest.rest_id)) {
                  return (
                    <Button
                      appearance="link"
                      onClick={() => showRestaurantUsedWarning()}
                      style={{ textDecoration: "line-through", opacity: 0.6 }}
                    >
                      Supprimer
                    </Button>
                  );
                } else {
                  return (
                    <Button
                      appearance="link"
                      onClick={() => confirmDeleteRestaurant(rest.rest_id)}
                    >
                      Supprimer
                    </Button>
                  );
                }
              }}
            </Cell>
          </Column>
        </Table>
      </div>
      <div className={styles.div_tableau_utilisateur}>
        <div className={styles.div_tableau_titre}>
          <h3 className={styles.tableau_titre}>
            Affecter un utilisateur à un restaurant
          </h3>
          <div className={styles.div_icone_ajouter}>
            <IconButton
              className={styles.icone_ajouter}
              icon={<PlusIcon />}
              onClick={() => handleModal("Affectation")}
            >
              Ajouter
            </IconButton>
          </div>
        </div>
        <Table virtualized data={data_affectation}>
          <Column>
            <HeaderCell>Identifiant</HeaderCell>
            <Cell dataKey="rest_util_id" onChange={handleChange} />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Utilisateur</HeaderCell>
            <Cell
              dataKey="util_id"
              type="affectation"
              data={data_utilisateur}
              onChange={handleChange}
            />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Nom</HeaderCell>
            <Cell dataKey="nom" type="affectation" onChange={handleChange} />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Restaurant</HeaderCell>
            <SelectCell
              dataKey="rest_id"
              type="affectation"
              data={data_restaurant}
              onChange={handleChange}
            />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Nom</HeaderCell>
            <Cell
              dataKey="rest_nom"
              type="affectation"
              onChange={handleChange}
            />
          </Column>

          <Column>
            <HeaderCell>Action</HeaderCell>
            <ActionCell
              dataKey="id_edit"
              type="affectation"
              onClick={handleEditState}
            />
          </Column>
          <Column>
            <HeaderCell>Supprimer</HeaderCell>
            <Cell style={{ padding: "6px" }}>
              {(affec) => {
                // Si la ligne est en mode édition, affichez le bouton "Annuler"
                if (affec.status === "EDIT") {
                  return (
                    <Button
                      appearance="link"
                      onClick={() =>
                        handleCancelEdit(affec.rest_util_id, "affectation", {
                          data_groupe,
                          setDataGroupe,
                          data_restaurant,
                          setDataRestaurant,
                          data_utilisateur,
                          setDataUtilisateur,
                          data_affectation,
                          setDataAffectation,
                        })
                      }
                    >
                      Annuler
                    </Button>
                  );
                }
                if (isAffectationOfCurrentUser(affec.util_id)) {
                  return (
                    <Button
                      appearance="link"
                      onClick={() => showAffectUsedWarning()}
                      style={{ textDecoration: "line-through", opacity: 0.6 }}
                    >
                      Supprimer
                    </Button>
                  );
                } else {
                  return (
                    <Button
                      appearance="link"
                      onClick={() =>
                        confirmDeleteAffectation(affec.rest_util_id)
                      }
                    >
                      Supprimer
                    </Button>
                  );
                }
              }}
            </Cell>
          </Column>
        </Table>
      </div>

      <div className={styles.div_tableau_utilisateur}>
        <div className={styles.div_tableau_titre}>
          <h3 className={styles.tableau_titre}>Utilisateur</h3>
          <div className={styles.div_icone_ajouter}>
            <IconButton
              className={styles.icone_ajouter}
              icon={<PlusIcon />}
              onClick={() => handleModal("Utilisateur")}
            >
              Ajouter
            </IconButton>
          </div>
        </div>
        <Table virtualized data={data_utilisateur}>
          <Column flexGrow={1}>
            <HeaderCell>Identifiant automatique</HeaderCell>
            <Cell dataKey="id" />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Identifiant de connexion</HeaderCell>
            <EditCell
              dataKey="nom"
              type="utilisateur"
              onChange={handleChange}
            />
          </Column>

          <Column>
            <HeaderCell>Mot de passe</HeaderCell>
            <EditCell
              dataKey="mdp"
              type="utilisateur"
              onChange={handleChange}
            />
          </Column>

          <Column width={200} resizable>
            <HeaderCell>Type</HeaderCell>
            <SelectCell
              dataKey="type"
              type="utilisateur"
              data={userTypeOptions}
              onChange={handleChange}
            />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Dernière connexion</HeaderCell>
            <Cell>
              {(rowData) => {
                if (!rowData.date_connexion) {
                  return "Aucune connexion";
                }

                const lastConnectionDate = new Date(rowData.date_connexion);
                const now = new Date();
                const formattedDate = format(
                  lastConnectionDate,
                  "dd/MM/yyyy HH:mm"
                );
                const hoursDiff = differenceInHours(now, lastConnectionDate);
                const daysDiff = differenceInDays(now, lastConnectionDate);
                const weeksDiff = differenceInWeeks(now, lastConnectionDate);
                const monthsDiff = differenceInMonths(now, lastConnectionDate);

                let timeDiffDisplay;
                if (hoursDiff < 1) {
                  timeDiffDisplay = `- moins d'une heure`;
                } else if (hoursDiff < 24) {
                  timeDiffDisplay = `- ${hoursDiff} heure${
                    hoursDiff > 1 ? "s" : ""
                  }`;
                } else if (daysDiff < 7) {
                  timeDiffDisplay = `- ${daysDiff} jour${
                    daysDiff > 1 ? "s" : ""
                  }`;
                } else if (weeksDiff < 4) {
                  timeDiffDisplay = `- ${weeksDiff} semaine${
                    weeksDiff > 1 ? "s" : ""
                  }`;
                } else if (monthsDiff < 12) {
                  timeDiffDisplay = `- ${monthsDiff} mois`;
                } else {
                  timeDiffDisplay = `+ de 1 an`;
                }

                return (
                  <>
                    {timeDiffDisplay}
                    <span style={{ fontSize: "smaller" }}>
                      {"  "}({formattedDate})
                    </span>
                  </>
                );
              }}
            </Cell>
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Groupe</HeaderCell>
            <Cell dataKey="grp_nom" />
          </Column>

          <Column>
            <HeaderCell>Action</HeaderCell>
            <ActionCell
              dataKey="id_edit"
              type="utilisateur"
              onClick={handleEditState}
            />
          </Column>

          <Column>
            <HeaderCell>Supprimer</HeaderCell>
            <Cell style={{ padding: "6px" }}>
              {(util) => {
                // Si la ligne est en mode édition, affichez le bouton "Annuler"
                if (util.status === "EDIT") {
                  return (
                    <Button
                      appearance="link"
                      onClick={() =>
                        handleCancelEdit(util.id, "utilisateur", {
                          data_groupe,
                          setDataGroupe,
                          data_restaurant,
                          setDataRestaurant,
                          data_utilisateur,
                          setDataUtilisateur,
                          data_affectation,
                          setDataAffectation,
                        })
                      }
                    >
                      Annuler
                    </Button>
                  );
                }
                if (!isCurrentUser(util.id)) {
                  return (
                    <Button
                      appearance="link"
                      onClick={() => confirmDeleteUser(util.id)}
                    >
                      Supprimer
                    </Button>
                  );
                } else {
                  return (
                    <Button
                      appearance="link"
                      onClick={showPopupMessage}
                      style={{ textDecoration: "line-through", opacity: 0.6 }}
                    >
                      Supprimer
                    </Button>
                  );
                }
              }}
            </Cell>
          </Column>
        </Table>
      </div>

      <Modal open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>{titreFormulaireAjout(ajout)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>{formulaireAjout(ajout)}</form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={gestionAjout} appearance="primary">
            Valider
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Annuler
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        open={deleteUserModalOpen}
        onClose={() => setDeleteUserModalOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer cet utilisateur ?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={deleteUser} appearance="primary">
            Oui, supprimer
          </Button>
          <Button
            onClick={() => setDeleteUserModalOpen(false)}
            appearance="subtle"
          >
            Annuler
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        open={deleteGroupModalOpen}
        onClose={() => setDeleteGroupModalOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            Êtes-vous sûr de vouloir supprimer ce groupe et tous les restaurants
            et affectations associés ?
          </div>
          <div
            style={{
              backgroundColor: "red",
              color: "white",
              padding: "10px",
              marginTop: "10px",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              style={{ marginRight: "10px" }}
            />
            <span>
              Attention : supprimer un restaurant supprimera également toutes
              les données qui lui sont affiliées.
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={deleteGroup} appearance="primary">
            Oui, supprimer
          </Button>
          <Button
            onClick={() => setDeleteGroupModalOpen(false)}
            appearance="subtle"
          >
            Annuler
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        open={deleteAffectationModalOpen}
        onClose={() => setDeleteAffectationModalOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer cette affectation ?
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              dispatch(deleteAffectation(affectationToDelete)).then(() => {
                setDataAffectation(
                  data_affectation.filter(
                    (affectation) =>
                      affectation.rest_util_id !== affectationToDelete
                  )
                );
                setDeleteAffectationModalOpen(false);
              });
            }}
            appearance="primary"
          >
            Oui, supprimer
          </Button>
          <Button
            onClick={() => setDeleteAffectationModalOpen(false)}
            appearance="subtle"
          >
            Annuler
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        open={deleteRestaurantModalOpen}
        onClose={() => setDeleteRestaurantModalOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Êtes-vous sûr de vouloir supprimer ce restaurant ?</div>
          <div
            style={{
              backgroundColor: "red",
              color: "white",
              padding: "10px",
              marginTop: "10px",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              style={{ marginRight: "10px" }}
            />
            <span>
              Attention : supprimer un restaurant supprimera également toutes
              les données qui lui sont affiliées.
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              dispatch(deleteRestaurant(restaurantToDelete)).then(() => {
                // Mettre à jour le tableau des restaurants
                setDataRestaurant(
                  data_restaurant.filter(
                    (restaurant) => restaurant.rest_id !== restaurantToDelete
                  )
                );

                // Rafraîchir le tableau des affectations
                dispatch(fetchAffectation()).then((updatedAffectations) => {
                  setDataAffectation(updatedAffectations);
                });

                setDeleteRestaurantModalOpen(false);
              });
            }}
            appearance="primary"
          >
            Oui, supprimer
          </Button>
          <Button
            onClick={() => setDeleteRestaurantModalOpen(false)}
            appearance="subtle"
          >
            Annuler
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
