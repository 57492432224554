import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchDashboard, fetchLive, dashboardSelector, liveSelector } from "./dashboardSlice";
import Alert from "react-bootstrap/Alert";
import { Line, Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import DatePicker from "react-datepicker";
import format from "date-fns/format";
import fr from "date-fns/locale/fr";
import styles from "./Dashboard.module.css";
import Spinner from "react-bootstrap/Spinner";
import { DateRangePicker } from "rsuite";
import Message from "rsuite/Message";
import toaster from "rsuite/toaster";
import LiveTable from "./LiveTable"; // Importer le nouveau composant
import Cookies from "js-cookie";
import Card from "react-bootstrap/Card";

export function Dashboard() {
  const ref = useRef();
  const dispatch = useDispatch();
  const { dashboard, loading, hasErrors } = useSelector(dashboardSelector);
  const live = useSelector(liveSelector); // Utilisation du sélecteur pour les données live
  const [showTable, setShowTable] = useState(
    Cookies.get("showTable") === "true" // Récupérer la préférence utilisateur
  );


  var date = new Date();
  var d_min = new Date(date.getFullYear(), date.getMonth(), 1);
  if (date === d_min) {
    d_min.setDate(d_min.getDate() - 1);
  }
  const [date_max, set_max_date] = useState(date);
  const [date_min, set_min_date] = useState(d_min);
  const [dateRange, setDateRange] = useState({});
  const [hasLoaded, setHasLoaded] = useState(false);
  const [liveLoaded, setLiveLoaded] = useState(false); // État local pour le chargement du tableau live
  const restaurant_choisi = useSelector((state) => state.connexion.restaurant_choisi);

  const [currentTime, setCurrentTime] = useState(new Date());
  const [timeUntilRefresh, setTimeUntilRefresh] = useState(400); // Chrono inversé (60 secondes)

  // Fonction pour rafraîchir uniquement le tableau direct
  const refreshTable = async () => {
    try {
      await dispatch(fetchLive("hebdomadaire")); // Appel API pour les données live
      setLiveLoaded(true); // Mettre à jour l'état local pour indiquer que le tableau live est chargé
    } catch (error) {
      console.error("Erreur lors du rafraîchissement du tableau :", error);
    }
  };

  // Effet pour gérer le chrono et le rafraîchissement du tableau
  useEffect(() => {
    // Met à jour le chrono chaque seconde
    const clockInterval = setInterval(() => {
      setCurrentTime(new Date());
      setTimeUntilRefresh((prev) => {
        if (prev > 1) {
          return prev - 1;
        } else {
          refreshTable(); // Rafraîchit le tableau à 0
          return 400; // Réinitialise le chrono
        }
      });
    }, 1000);

    // Charge initiale des données
    refreshTable();

    return () => {
      clearInterval(clockInterval);
    };
  }, []); // Pas de dépendances pour éviter les rechargements inutiles

  // Charge initiale des données du tableau
  useEffect(() => {
    refreshTable();
  }, [restaurant_choisi]);

  useEffect(() => {
    dispatch(fetchDashboard("hebdomadaire")).then(() => setHasLoaded(true));
  }, [restaurant_choisi]);

  useEffect(() => {
    dispatch(fetchLive("hebdomadaire")).then(() => setLiveLoaded(true));
  }, [restaurant_choisi]);

  const calculateDaysDifference = (start, end) => {
    const oneDay = 24 * 60 * 60 * 1000; // Milliseconds in one day
    return Math.round(Math.abs((end - start) / oneDay));
  };

  // Gérer l'affichage/mise à jour des préférences utilisateur
  const toggleTableVisibility = () => {
    const newShowTable = !showTable;
    setShowTable(newShowTable);
    Cookies.set("showTable", newShowTable, { expires: 30 }); // Enregistrer dans les cookies
  };

  const handleDateChange = (range) => {
    if (range[0] && range[1]) {
      const daysDifference = calculateDaysDifference(range[0], range[1]);
      if (daysDifference > 45) {
        const message = (
          <Message showIcon type="warning">
            L'intervalle de dates ne peut pas dépasser 45 jours.
          </Message>
        );
        toaster.push(message, "topEnd");
      } else {
        setDateRange(range);
      }
    } else {
      setDateRange(range);
    }
  };

  const imprEdit = live?.etatDuService; // Vérification que live existe avant d'accéder à la clé

  if (hasLoaded) {
    // Calculer la valeur maximale pour chaque graphique
    const calculateMaxValue = (dataArray, increment = 500) => {
      const maxDataValue = Math.max(...dataArray);
      const suggestedMax = Math.ceil((maxDataValue + increment) / 100) * 100;
      return suggestedMax;
    };

    const calculateMaxValueRounded = (dataArray) => {
      const maxDataValue = Math.max(...dataArray);
      const suggestedMax = Math.ceil((maxDataValue + 50) / 50) * 50;
      return suggestedMax;
    };

    const maxDataValue_ca = calculateMaxValue([...dashboard[1], ...dashboard[2], ...dashboard[3]]);
    const maxDataValue_couvert = calculateMaxValueRounded([...dashboard[4], ...dashboard[9]]);
    const maxDataValue_famille = calculateMaxValue([...dashboard[8]]);

    const data_ca = {
      labels: [...dashboard[0]],
      datasets: [
        {
          label: "CA Restaurant avec taxes",
          lineTension: 0,
          data: [...dashboard[1]],
          fill: false,
          backgroundColor: "rgb(244,0,32)",
          borderColor: "rgb(247,76,98)",
        },
        {
          label: "CA Brasserie avec taxes",
          lineTension: 0,
          data: [...dashboard[2]],
          fill: false,
          backgroundColor: "rgb(10,117,173)",
          borderColor: "rgb(83,158,197)",
        },
        {
          label: "Total avec taxes",
          lineTension: 0,
          data: [...dashboard[3]],
          fill: false,
          backgroundColor: "rgb(139,125,123)",
          borderColor: "rgb(205,183,181)",
        },
      ],
    };

    const data_couvert = {
      labels: [...dashboard[0]],
      datasets: [
        {
          label: "Nombre de couverts",
          lineTension: 0,
          data: [...dashboard[4]],
          fill: false,
          backgroundColor: "rgb(244,0,32)",
          borderColor: "rgb(247,76,98)",
        },
        {
          label: "Nombre de tickets bar",
          lineTension: 0,
          data: [...dashboard[9]],
          fill: false,
          backgroundColor: "rgb(10,117,173)",
          borderColor: "rgb(83,158,197)",
        },
      ],
    };

    const data_annulation = {
      labels: [...dashboard[0]],
      datasets: [
        {
          label: "Pourcentage d'annulations",
          lineTension: 0,
          data: [...dashboard[5]],
          fill: false,
          backgroundColor: "rgb(244,0,32)",
          borderColor: "rgb(247,76,98)",
        },
      ],
    };

    const data_famille = {
      labels: [...dashboard[7]],
      datasets: [
        {
          label: "CA H.T",
          lineTension: 0,
          data: [...dashboard[8]],
          fill: false,
          backgroundColor: "rgb(244,0,32)",
          borderColor: "rgb(247,76,98)",
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          max: maxDataValue_ca, // Utiliser la valeur maximale calculée pour le CA
        },
      },
      plugins: {
        legend: {
          display: true,
          position: 'bottom',  // Déplace la légende en dessous du graphique
        },
        datalabels: {
          clamp: true,
          anchor: "center",
          color: "black",
          align: "top",
          font: {
            weight: "bold",
            size: 12,
          },
        },
      },
    };

    const options_bar = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          max: maxDataValue_couvert, // Utiliser la valeur maximale calculée pour les couverts
        },
      },
      plugins: {
        legend: {
          display: true,
          position: 'bottom',  // Déplace la légende en dessous du graphique
        },
        datalabels: {
          color: "black",
          align: "mid",
          font: {
            weight: "bold",
            size: 12,
          },
        },
      },
    };

    const options_annulation = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          max: 100,
          min: 0,
        },
      },
      plugins: {
        legend: {
          display: true,
          position: 'bottom',  // Déplace la légende en dessous du graphique
        },
        datalabels: {
          color: "black",
          align: "top",
          font: {
            weight: "bold",
            size: 12,
          },
        },
      },
    };

    const options_famille = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          max: maxDataValue_famille, // Utiliser la valeur maximale calculée pour les familles
        },
      },
      plugins: {
        legend: {
          display: true,
          position: 'bottom',  // Déplace la légende en dessous du graphique
        },
        datalabels: {
          color: "black",
          align: "mid",
          font: {
            weight: "bold",
            size: 12,
          },
        },
      },
    };

    const intervalle = () => {
      if (dateRange[0] > dateRange[1]) {
        const message = (
          <Message showIcon type="warning">
            La date de début doit être antérieure à la date de fin.
          </Message>
        );

        toaster.push(message, "topEnd");
      } else if (dateRange[1] > new Date()) {
        const message = (
          <Message showIcon type="warning">
            Impossible d'afficher une date dans le futur !
          </Message>
        );

        toaster.push(message, { placement: "topEnd" });
      } else {
        dispatch(
          fetchDashboard(
            "personnaliser",
            format(dateRange[0], "yyyyMMdd"),
            format(dateRange[1], "yyyyMMdd")
          )
        );
      }
    };

    const line = () => {
      return (
        <Line
          ref={ref}
          data={data_ca}
          options={options}
          plugins={[ChartDataLabels]}
        />
      );
    };

    const bar = () => {
      return (
        <Bar
          ref={ref}
          data={data_couvert}
          options={{
            ...options_bar,
            scales: {
              y: {
                beginAtZero: true,
                max: maxDataValue_couvert,
              },
            },
          }}
          plugins={[ChartDataLabels]}
        />
      );
    };

    if (loading)
      return (
        <Alert variant="secondary">
          {" "}
          Chargement du dashboard{" "}
          <Spinner styles={styles.spinner} animation="border" />{" "}
        </Alert>
      );
    if (hasErrors)
      return (
        <Alert variant="danger"> Impossible de charger le dashboard </Alert>
      );
    if (hasLoaded) {
      return (
        <div>
          <div className={styles.switch}>
            <ButtonGroup as="div" aria-label="Basic example">
              <Button
                as="div"
                variant="secondary"
                className={styles.bouton_dashboard}
                onClick={() => dispatch(fetchDashboard("hebdomadaire"))}
              >
                Hebdomadaire
              </Button>
              <Button
                as="div"
                variant="secondary"
                className={styles.bouton_dashboard}
                onClick={() => dispatch(fetchDashboard("mensuel"))}
              >
                Mois en cours
              </Button>
            </ButtonGroup>
            <div className={styles.div_date}>
              <div className={styles.date}>Personnaliser :</div>
              <DateRangePicker
                showOneCalendar={true}
                locale={fr}
                format="dd/MM/yyyy"
                placeholder="Choisir un intervalle"
                isoWeek={true}
                onChange={handleDateChange}
              />
              <div className={styles.date}>
                <Button
                  type="submit"
                  variant="outline-danger"
                  className={styles.button}
                  onClick={() => intervalle()}
                >
                  Valider
                </Button>
              </div>
            </div>
          </div>
          {imprEdit === null ? null : imprEdit === 0 ? (
            <Card className={styles.dashboardCard}>
              <Card.Header className={styles.cardHeader}>
                <h2 className={styles.titleLive}>Tableau Live</h2>
                <Button
                  variant={showTable ? "secondary" : "danger"}
                  className={`${styles.toggleButton} ${
                    showTable ? styles.hideButton : styles.showButton
                  }`}
                  onClick={toggleTableVisibility}
                >
                  {showTable ? "Cacher" : "Afficher"}
                </Button>
              </Card.Header>
              {showTable && (
                <div className={`card-body ${styles.customCardBody}`}>
                  {liveLoaded ? (
                    <LiveTable live={live} timeUntilRefresh={timeUntilRefresh} />
                  ) : (
                    <Alert variant="secondary">
                      Chargement du tableau <Spinner animation="border" />
                    </Alert>
                  )}
                </div>
              )}
            </Card>
          ) : (
            <Card className={`${styles.dashboardCard} ${styles.noServiceCard}`}>
              <Card.Body>
                <h4>Aucun service en cours</h4>
              </Card.Body>
            </Card>
          )}
          <div className={styles.grille}>
            <article className={styles.graphique}>
              <h2>Chiffre d'affaires</h2>
              {line()}
            </article>
            <article className={styles.graphique}>
              <h2>Nombre de couverts et tickets</h2>
              {bar()}
            </article>
            <article className={styles.graphique}>
              <h2>Pourcentage d'annulations</h2>
              <Line
                ref={ref}
                data={data_annulation}
                options={options_annulation}
                plugins={[ChartDataLabels]}
              />
            </article>
            <article className={styles.graphique}>
              <h2>Groupe de famille</h2>
              <Bar
                ref={ref}
                data={data_famille}
                options={options_famille}
                plugins={[ChartDataLabels]}
              />
            </article>
          </div>
        </div>
      );
    }
  } else {
    return (
      <Alert variant="secondary">
        {" "}
        Chargement du dashboard{" "}
        <Spinner styles={styles.spinner} animation="border" />{" "}
      </Alert>
    );
  }
}
